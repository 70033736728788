import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    first_name: Yup.string().required("O nome é obrigatório"),
    last_name: Yup.string().required("O sobrenome é obrigatório"),
    phone: Yup.string().required("O telefone é obrigatório"),
    email: Yup.string()
      .email("Email inválido")
      .required("O email é obrigatório"),
  }),
];

export const validationsEdit = [
  Yup.object().shape({
    first_name: Yup.string().required("O nome é obrigatório"),
    last_name: Yup.string().required("O sobrenome é obrigatório"),
    phone: Yup.string().required("O telefone é obrigatório"),
    email: Yup.string()
      .email("Email inválido")
      .required("O email é obrigatório"),
  }),
];

export default validations;
