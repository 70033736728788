/* eslint-disable no-unused-vars */

// Material Dashboard 2 PRO React base styles
import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';
import boxShadows from 'assets/theme/base/boxShadows';

// Material Dashboard 2 PRO React helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

const { white, primary } = colors;
const { borderRadius } = borders;
const { tabsBoxShadow } = boxShadows;

const tabs = {
  styleOverrides: {
    root: {
      position: 'relative',
      // backgroundColor: primary,
      borderRadius: borderRadius.xl,
      minHeight: 'unset',
      padding: pxToRem(4),

    },

    flexContainer: {
      height: '100%',
      position: 'relative',
      zIndex: 10,
    },

    fixed: {
      overflow: 'unset !important',
      overflowX: 'unset !important',
    },

    vertical: {
      '& .MuiTabs-indicator': {
        width: '100%',
      },
    },

    indicator: {
      height: '100%',
      borderBottom: `4px solid ${primary.main}`,
      backgroundColor: white.main,
      transition: 'all 500ms ease',
      // borderRadius: borderRadius.lg,
      // boxShadow: tabsBoxShadow.indicator,
      // color: `${white.main} !important`,
    },
  },
};

export default tabs;
