// import styled from "styled-components";

export const DropzoneFilesContainer = {
  display: "flex",
  flexDirection: "column",
  fontFamily: "Poppins",
  width: "100%",
  height: "100%",
  p: {
    color: "black",
  },
  margin: "10px 0",
};

export const DropzoneFiles = {
  position: "relative",
  width: "100%",
  height: "100%",
  padding: "15px 5px",
  border: "2px dashed #cfcfcf",
  display: "flex",
  flexDirection: "column",
  justifycontent: "center",
  alignItems: "center",

  fontstyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#bfbfbf",
  cursor: "pointer !important",
};

export const InputFile = {
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "100%",
  opacity: 0,
  cursor: "pointer !important",
};
export const ImgFile = {
  width: "15%",
  cursor: "pointer !important",
  padding: "10px",
  maxWidth: "200px",
};
