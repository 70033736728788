/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Card, Grid, Icon } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";
import DataTableApi from "shared/components/tables/DatatableApi";

import { useEffect, useState } from "react";
import MDButton from "shared/components/MDButton";
import NotificationItem from "shared/examples/Items/NotificationItem";

import bannersService from "shared/services/banners/banners.service";

import PopUp, { dialogButton } from "shared/components/pop_up";
import { useNavigate } from "react-router-dom";
import MDProgress from "shared/components/MDProgress";
import toastError from "shared/components/snackbar/error/toastError";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import { errorMultipleMsgToast } from "utils/errorStatusCode";
import { maskPhone } from "utils/masks";
import FontsCreateModal from "./create";
import FontsEditModal from "./edit";
import fontsService from "shared/services/fonts/fonts.service";
import { applyFonts } from "utils";

const mockData = [
  {
    id: 1,
    font_link: "https://fonts.googleapis.com/css2?family=Lemon&display=swap",
    name: "Lemon",
    format: "truetype",
  },
  {
    id: 2,
    font_link:
      "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap",
    name: "Bebas Neue",
    format: "truetype",
  },
];

function FontList() {
  const navigate = useNavigate();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [userStatus, setUserStatus] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [filter, setFilter] = useState("");
  const [filterSearch, setFilterSearch] = useState("");

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [fontItem, setFontItem] = useState();

  const handleSampleFont = (row) => {
    const { original } = row;

    return (
      <MDTypography style={{ fontFamily: `${original.name}, Roboto` }}>
        abcdefghABCDEFGH
      </MDTypography>
    );
  };

  const handleLinkFont = (row) => {
    const { original } = row;

    return (
      <MDTypography
        variant="body2"
        sx={{
          whiteSpace: "nowrap",
          overflowX: "scroll",
          // textOverflow: "ellipsis",
          width: "200px",
        }}
      >
        {original.file_link}
      </MDTypography>
    );
  };

  const dataTableData = {
    columns: [
      {
        Header: "nome",
        accessor: "name",
      },
      {
        Header: "link",
        accessor: "file_link",
        Cell: ({ cell: { row } }) => handleLinkFont(row),
        width: "20%",
      },
      {
        Header: "Amostra",
        accessor: "sample",
        Cell: ({ cell: { row } }) => handleSampleFont(row),
      },
      {
        Header: "ações",
        accessor: "action",
        width: "15%",
      },
    ],

    rows: dataTable || [],
    // rows: mockData || [],
  };

  const loadFonts = async (page = 1, limit = 5, filter = "") => {
    try {
      setLoadingData(true);
      const filterValue = filter !== "" ? `&filter=${filter.trim()}` : "";
      const res = await fontsService.list(
        `?page=${page}&limit=${limit}${filterValue}`
      );

      if (res) {
        setDataTable(res.data);
        setTableTotal(res.meta.totalCount);

        // mockData.forEach(({ name, font_link, format }) => {
        //   applyFonts(name, font_link, format);
        // });
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  // const handleChange = (event) => {
  //   const { value } = event.target;
  //   if (value === "") {
  //     loadUsers(1, tableLimit);
  //     setTablePage(1);
  //     setFilterSearch("");
  //   }
  //   setFilter(value);
  // };

  // const handleClickSearch = () => {
  //   setFilterSearch(filter.trim());
  //   setTablePage(1);
  //   loadUsers(1, tableLimit, filter);
  // };

  useEffect(() => {
    loadFonts(tablePage, tableLimit, filterSearch);
    return () => {
      setDataTable();
      setTableTotal(0);
    };
  }, [tablePage, tableLimit]);

  return (
    <>
      <MDBox width="100%">
        <MDBox>
          <MDBox lineHeight={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <MDTypography variant="h5" fontWeight="medium">
                  Fontes
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDBox width="12rem" ml="auto">
                  <MDButton
                    variant="gradient"
                    color="primary"
                    size="small"
                    fullWidth
                    onClick={() => setOpenModal(true)}
                  >
                    CRIAR
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          {/* <MDBox mt={2}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <MDInput
                      type="text"
                      name="filter"
                      value={filter}
                      size="small"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            sx={{ visibility: filter ? "visible" : "hidden" }}
                            size="small"
                            onClick={() =>
                              handleChange({ target: { value: "" } })
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={2} display="flex">
                    <MDButton
                      disabled={!filter}
                      variant="gradient"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={handleClickSearch}
                    >
                      BUSCAR
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox> */}
        </MDBox>
        {loadingData ? (
          <MDProgress
            variant="gradient"
            variantProgress="indeterminate"
            color="primary"
          />
        ) : (
          <DataTableApi
            table={dataTableData}
            onRowSelected={setRowSelected}
            entriesPerPage={{ defaultValue: tableLimit }}
            setTableLimit={setTableLimit}
            tableLimit={tableLimit}
            setTablePage={setTablePage}
            tablePage={tablePage}
            tableTotal={tableTotal}
          >
            {[
              <NotificationItem
                onClick={() => {
                  const { original } = rowSelected;
                  setOpenEditModal(true);
                  setFontItem(original);
                }}
                icon={<Icon>create</Icon>}
                title="Editar"
                key="option_edit"
              />,
            ]}
          </DataTableApi>
        )}
      </MDBox>
      <FontsCreateModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          loadFonts(tablePage, tableLimit, filterSearch);
        }}
      />
      <FontsEditModal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
          loadFonts(tablePage, tableLimit, filterSearch);
        }}
        data={fontItem}
      />
    </>
  );
}

export default FontList;
