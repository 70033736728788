import localStorageService from "shared/services/localStorage";
import StorageTypes from "shared/services/localStorage/storageTypes";

export const statusOptions = [0, 1];

export const rolesOptions = [0, 1];

export function translateRoleNumberToString(value) {
  if (value === 1 || value === "1") return "Cliente";
  if (value === 0 || value === "0") return "Master";
  return value;
}

export function translateStatus(value) {
  if (value === 1 || value === "1") return "Ativo";
  if (value === 0 || value === "0") return "Inativo";
  return value;
}

export function parseDateFromApi(dateString) {
  if (!dateString) return "";
  return dateString.split("T")[0];
}

export function formatDateFromApi(dateYYYYMMDD, separator = "/") {
  const splitedData = dateYYYYMMDD.includes("T")
    ? dateYYYYMMDD.split("T")[0]
    : dateYYYYMMDD;
  const dates = splitedData.split("-");
  const day = dates[2].padStart(2, "0");
  const month = dates[1].padStart(2, "0");
  const year = dates[0].padStart(4, "0");

  return `${day}${separator}${month}${separator}${year}`;
}

export function userLogged() {
  const user = localStorageService.get(StorageTypes.USER);
  if (user) {
    // const user = jwtDecode(token);
    return user;
  } else {
    return null;
  }
}

export function filterRoutesByRole(routes) {
  const user = userLogged()?.user;

  if (user) {
    return routes.filter(
      (route) => !route.roles || route.roles.includes(Number(user.role))
    );
  } else {
    return routes;
  }
}

export const ufOptions = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

export const fontFamilyList = [
  "Arial",
  "By Note",
  "CA saygon",
  "Courier New",
  "Georgia",
  "Hando",
  "Helvetica",
  "Lato",
  "Metrisch",
  "Montserrat",
  "Nunito",
  "Oriole display",
  "Open Sans",
  "Poppins",
  "Roboto",
  "Times New Roman",
  "Verdana",
  // "Nova Square",

  // 'Source Sans Pro','Raleway'
];

export const xmlCalibrationKeys = [
  "product_key",
  "pid",
  "name",
  // "category",
  // "description",
  "image_url",
  "price",
  "installment_price",
  "installment_month",
];

export function translateXMLCalibrationKeys(value) {
  switch (value) {
    case "product_key":
      return "Chave do produto";
    case "pid":
      return "Código do produto";
    case "name":
      return "Nome do produto";
    case "category":
      return "Categoria";
    case "description":
      return "Descrição";
    case "image_url":
      return "Link da imagem do produto";
    case "price":
      return "Preço do produto";
    case "installment_price":
      return "Valor da parcela";
    case "installment_month":
      return "Quantidade de parcelas";

    default:
      return value;
  }
}
