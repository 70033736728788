import { Autocomplete, Card } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";
import { useState } from "react";
import MDButton from "shared/components/MDButton";
import { Form, Formik } from "formik";
import FormField from "shared/components/form_field";
import initialValues from "../schemas/initial_values";
import validations from "../schemas/validations";
import professionalsService from "shared/services/professionals/professionals.service";
import { maskPhone } from "utils/masks";
import mockProfessional from "../mocks/mockProfessional";
import { formFieldDDIAdornment } from "shared/components/form_field/form_field_configs";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import {
  renderInput,
  handleValue,
  setValue,
} from "shared/components/autocomplete/autocompleteConfigs";
import { maskCEP, maskCPF, maskCNPJ } from "utils/masks";
import { ufOptions } from "./../../../../utils/essentialData";
import { errorMultipleMsgToast } from "utils/errorStatusCode";
import { removeEmptyFields } from "utils";

function ProfessionalsCreate() {
  const [mockData, setMockData] = useState();

  const handleSubmit = async (values, actions) => {
    try {
      await professionalsService.create(removeEmptyFields(values));
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess(
        "Usuário criado com sucesso",
        "",
        true,
        "/users",
        "Redirecionar para usuários"
      );
    } catch (e) {
      errorMultipleMsgToast(e);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={mockData ?? initialValues}
        validationSchema={validations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const {
            first_name: first_nameV,
            last_name: last_nameV,
            phone: phoneV,
            email: emailV,
            document: documentV,
            company_name: company_nameV,
            postal_code: postal_codeV,
            street: streetV,
            neighborhood: neighborhoodV,
            city: cityV,
            state: stateV,
            complement: complementV,
            banner_credits: banner_creditsV,
          } = values;
          return (
            <Form id="user-create-form" autoComplete="off">
              <Card id="basic-info" sx={{ overflow: "visible" }}>
                <MDBox p={3}>
                  <MDTypography variant="h5">Novo Usuário</MDTypography>
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="first_name"
                    label="Nome *"
                    type="text"
                    value={first_nameV}
                    error={errors.first_name && touched.first_name}
                    success={first_nameV.length > 0 && !errors.first_name}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="last_name"
                    label="Sobrenome *"
                    type="text"
                    value={last_nameV}
                    error={errors.last_name && touched.last_name}
                    success={last_nameV.length > 0 && !errors.last_name}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="phone"
                    label="Telefone *"
                    type="text"
                    value={phoneV}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("phone", maskPhone(value));
                    }}
                    InputProps={{
                      startAdornment: formFieldDDIAdornment(),
                    }}
                    error={errors.phone && touched.phone}
                    success={phoneV.length > 0 && !errors.phone}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="email"
                    label="Email *"
                    type="email"
                    value={emailV}
                    error={errors.email && touched.email}
                    success={emailV.length > 0 && !errors.email}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="document"
                    label="CNPJ/CPF *"
                    type="document"
                    value={documentV}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length > 14)
                        setFieldValue("document", maskCNPJ(value));
                      else setFieldValue("document", maskCPF(value));
                    }}
                    error={errors.document && touched.document}
                    success={documentV.length > 0 && !errors.document}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="company_name"
                    label="Nome da empresa *"
                    type="company_name"
                    value={company_nameV}
                    error={errors.company_name && touched.company_name}
                    success={company_nameV.length > 0 && !errors.company_name}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="postal_code"
                    label="CEP *"
                    type="text"
                    value={postal_codeV}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("postal_code", maskCEP(value));
                    }}
                    error={errors.postal_code && touched.postal_code}
                    success={postal_codeV.length > 0 && !errors.postal_code}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="street"
                    label="Logradouro *"
                    type="text"
                    value={streetV}
                    error={errors.street && touched.street}
                    success={streetV.length > 0 && !errors.street}
                  />
                </MDBox>

                {/* <MDBox px={3}>
                  <FormField
                    name="number"
                    label="Número *"
                    type="number"
                    value={numberV}
                    error={errors.number && touched.number}
                    success={numberV.length > 0 && !errors.number}
                  />
                </MDBox> */}

                <MDBox px={3}>
                  <FormField
                    name="complement"
                    label="Complemento"
                    type="text"
                    value={complementV}
                    error={errors.complement && touched.complement}
                    success={complementV.length > 0 && !errors.complement}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="neighborhood"
                    label="Bairro *"
                    type="text"
                    value={neighborhoodV}
                    error={errors.neighborhood && touched.neighborhood}
                    success={neighborhoodV.length > 0 && !errors.neighborhood}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="city"
                    label="Cidade *"
                    type="text"
                    value={cityV}
                    error={errors.city && touched.city}
                    success={cityV.length > 0 && !errors.city}
                  />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    options={ufOptions ?? []}
                    getOptionLabel={(option) => option || ""}
                    value={handleValue(stateV)}
                    isOptionEqualToValue={(o, v) => o === v}
                    onChange={(e, value) =>
                      setValue(setFieldValue, "state", value)
                    }
                    renderInput={(params) =>
                      renderInput("form", params, "state", "UF *", handleBlur)
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="banner_credits"
                    label="Créditos de banner *"
                    type="number"
                    value={banner_creditsV}
                    error={errors.banner_credits && touched.banner_credits}
                    success={
                      banner_creditsV.length > 0 && !errors.banner_credits
                    }
                  />
                </MDBox>

                <MDBox display="flex" gap="5px" py={3} px={3} ml="auto">
                  <MDButton
                    color="secondary"
                    type="button"
                    size="large"
                    fullWidth
                    onClick={() => setMockData(mockProfessional)}
                  >
                    Preencher
                  </MDButton>
                  <MDButton
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                    size="large"
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default ProfessionalsCreate;
