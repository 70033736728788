import { isAxiosError } from "axios";
import {
  XML_UPLOAD_API,
  XML_READER_API,
  XML_CALIBRATION_API,
} from "./calibration";
import customAxios from "../axiosCustom";
import errorStatusCode from "utils/errorStatusCode";
import ReactDOMServer from "react-dom/server";
import { parseString } from "xml2js";

const errorMsgHttp = (data, defaultMsg) => {
  if (typeof data === "string") {
    return data;
  } else {
    const element = (
      <div>
        {data.map((msg, i) => (
          <p key={i}>{msg}</p>
        ))}
      </div>
    );
    const jsxString = ReactDOMServer.renderToString(element);
    return jsxString;
  }
};

const identifyXmlTags = (xmlString) => {
  let jsonObject = {
    product_key: '',
    pid: '',
    name: '',
    image_url: '',
    price: '',
    installment_price: '',
    installment_month: ''
  };

  parseString(xmlString, { explicitArray: false }, (err, result) => {
    if (err) {
      console.log("ERR", err);
      throw new Error('Erro ao parsear XML');
    }

    const channel = result.rss ? result.rss.channel : result.feed;

    const mainTag = channel.item || channel.entry || null;

    if (mainTag) {
      jsonObject.product_key = channel.item ? 'item' : 'entry';   
      jsonObject.pid = mainTag[0]['g:id'] ? 'g:id' : mainTag[0]['id'] ? 'id' : '';
      jsonObject.name = mainTag[0]['g:title'] ? 'g:title' : mainTag[0]['title'] ? 'title' : '';
      jsonObject.image_url = mainTag[0]['g:image_link'] ? 'g:image_link' : mainTag[0]['image_link'] ? 'image_link' : '';
      jsonObject.price = mainTag[0]['g:price'] ? 'g:price' : mainTag[0]['price'] ? 'price' : '';
      const installment = mainTag[0]['g:installment'] || {};
      jsonObject.installment_price = installment['g:amount'] ? 'g:amount' : '';
      jsonObject.installment_month = installment['g:months'] ? 'g:months' : '';
    } else {
      console.log("Tag de produto não encontrada");
    }
  });

  return jsonObject;
};

class CalibrationService {
  createXML = async (body, loadFunction = () => {}) => {
    try {
      const response = await customAxios.post(XML_UPLOAD_API, body, {
        onUploadProgress: (event) => loadFunction(event),
      });

      if (!response) new Error("Não foi possível registrar XML");
      const jsonData = identifyXmlTags(response.data.file_string);
      console.log("AQUIIIII", { response: response.data, xml_tags:jsonData });
      return { response: response.data, xml_tags:jsonData };
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível criar XML";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data?.message);

          case 404:
            throw new Error(error.response?.data?.message);

          case 409:
            throw new Error(error.response?.data?.message);

          case 422:
            throw new Error(
              errorMsgHttp(error.response?.data?.message, defaultMsg)
            );

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  getXML = async () => {
    try {
      const response = await customAxios.get(`${XML_UPLOAD_API}`);

      if (!response) new Error("Não foi possível carregar XML");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const defaultMsg = "Não foi possível criar XML";

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error("XML não registrado");

          case 417:
            throw new Error(
              errorMsgHttp(error.response?.data?.message, defaultMsg)
            );

          default:
            throw new Error("Não foi possível carregar XML");
        }
      }

      throw new Error(error);
    }
  };

  readerXML = async (pid) => {
    try {
      const response = await customAxios.get(`${XML_READER_API}/${pid}`);

      if (!response) new Error("Não foi possível carregar XML");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error("XML não registrado");

          default:
            throw new Error("Não foi possível carregar XML");
        }
      }

      throw new Error(error);
    }
  };

  deleteXML = async (uuid) => {
    try {
      const response = await customAxios.delete(`${XML_UPLOAD_API}/${uuid}`);

      if (!response) new Error("Não foi possível apagar XML");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível apagar XML";

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 422:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  createCalibration = async (body) => {
    try {
      const response = await customAxios.post(XML_CALIBRATION_API, body);

      if (!response) new Error("Não foi possível criar Calibragem");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível criar Calibragem";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data?.message);

          case 404:
            throw new Error(error.response?.data?.message);

          case 409:
            throw new Error(error.response?.data?.message);

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  getCalibration = async () => {
    try {
      const response = await customAxios.get(`${XML_CALIBRATION_API}`);

      if (!response) new Error("Não foi possível carregar Calibragem");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error("Calibragem não registrada");

          default:
            throw new Error("Não foi possível carregar Calibragem");
        }
      }

      throw new Error(error);
    }
  };

  deleteCalibration = async (uuid) => {
    try {
      const response = await customAxios.delete(
        `${XML_CALIBRATION_API}/${uuid}`
      );

      if (!response) new Error("Não foi possível apagar Calibragem");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível apagar Calibragem";

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 422:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };
}

export default new CalibrationService();
