const mockProfessional = {
  first_name: "fulano",
  last_name: "profissional",
  phone: "(11)11111-1111",
  email: "teste@teste.com",
  password:"",
  role: 1,
  status: true,
  document: "22.535.612/0001-11",
  company_name: "Empresa teste",
  postal_code: "01001000",
  street: "Praça da Sé - lado ímpar",
  neighborhood: "Sé",
  city: "São Paulo",
  state: "SP",
  complement: "",
  banner_credits: 15,
};

export default mockProfessional;
