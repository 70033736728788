import { fabric } from "fabric";
import { isBackgroundColorDark } from "utils"; 
import { handleStartPositionByClick } from "./canvasFunctionsUtils";
import { getStyle } from "./styleBySize"; // Corrected casing to match the file name on disk

function calculateAtributeSize(
  canvasWidth,
  canvasHeight,
  originalAtributeSize = 24,
  originalWidth = 800,
  originalHeight = 500
) {
  const widthFactor = canvasWidth / originalWidth;
  const heightFactor = canvasHeight / originalHeight;

  const largerFactor = Math.max(widthFactor, heightFactor);
  const newFontSize = originalAtributeSize * largerFactor;

  return Math.ceil(newFontSize);
}

export const addText = (
  canvas,
  text = "Digite o texto",
  fontFamily = "Arial",
  fontSize = 24,
  fill = "#000000",
  left = 50,
  top = 50
) => {
  const colorFont = isBackgroundColorDark(canvas.backgroundColor);
  const fatorEscala = 1;

  const newFont = calculateAtributeSize(
    canvas.width / canvas.getZoom(),
    canvas.height / canvas.getZoom()
  );

  const newWidth = calculateAtributeSize(
    canvas.width / canvas.getZoom(),
    canvas.height / canvas.getZoom(),
    300
  );

  const textRes = new fabric.Textbox(text, {
    left: left,
    top: top,
    fontFamily: fontFamily,
    fontSize: newFont,
    textBackgroundColor: "black",
    stroke: "transparent",
    strokeWidth: 0,
    fontWeight: "normal",
    fill: colorFont,
    antiAlias: true,
    width: newWidth,
  });
  textRes.scaleX = fatorEscala;
  textRes.scaleY = fatorEscala;
  canvas.add(textRes);

  canvas.setActiveObject(textRes);
  canvas.getActiveObject();
  canvas.renderAll();
};

export const addMultipleText = (
  canvas,
  text = "Digite o texto",
  fontFamily = "Arial",
  type,
) => {
  const colorFont = isBackgroundColorDark(canvas.backgroundColor);
  const fatorEscala = 1;

  const newFont = calculateAtributeSize(
    canvas.width / canvas.getZoom(),
    canvas.height / canvas.getZoom()
  );
 
  const newWidth = getScreenSize(canvas.vptCoords.br)
  console.log('size ==> ', newWidth)
  const textRes = new fabric.Textbox(text, getStyle(newWidth, type, colorFont));
  textRes.scaleX = fatorEscala;
  textRes.scaleY = fatorEscala;
  canvas.add(textRes);
  return textRes;
};

function getScreenSize({ x, y }) {
  const roundedX = Math.round(x);
  const roundedY = Math.round(y);
  return `${roundedX}x${roundedY}`;
}

export const addTextOnclick = (
  canvas,
  stateV,
  setMode,
  reset,
  setType,
  text = "Digite o texto",
  fontFamily = "Arial",
  fontSize = 24,
  fill = "#ffffff"
) => {
  const backgroundImage = canvas.get("backgroundImage");
  let colorFont;
  if (backgroundImage) {
    colorFont = fill;
  } else colorFont = isBackgroundColorDark(canvas.backgroundColor);
  const fatorEscala = 1;

  const newFont = calculateAtributeSize(
    canvas.width / canvas.getZoom(),
    canvas.height / canvas.getZoom()
  );

  const newWidth = calculateAtributeSize(
    canvas.width / canvas.getZoom(),
    canvas.height / canvas.getZoom(),
    145
  );
  canvas.defaultCursor = "text";
  document.body.style.cursor = "text";

  let newText;
  canvas.on("mouse:down", function (event) {
    if (stateV) {
      const pointer = canvas.getPointer(event.e);
      const x = pointer.x - 10;
      const y = pointer.y - 10;

      newText = new fabric.Textbox(text, {
        left: x,
        top: y,
        fontFamily: fontFamily,
        fontSize: newFont,
        textBackgroundColor: "transparent",
        stroke: "transparent",
        strokeWidth: 0,
        fontWeight: "normal",
        fill: colorFont,
        antiAlias: true,
        scaleX: fatorEscala,
        scaleY: fatorEscala,
        width: newWidth,
        // centeredScaling: false,
        // lockUniScaling: true,
      });

      newText.setControlsVisibility({
        // tl: false, //top-left
        // mt:false, // middle-top
        // tr: false, //top-right
        // ml:false, //middle-left
        // mr:false, //middle-right
        // bl: false, // bottom-left
        // mb: false, //middle-bottom
        // br: false, //bottom-right
      });

      canvas.add(newText);
      // centralizar posição do mouse no objeto criado
      // newText.set({
      //   left: pointer.x - newText.width / 2,
      //   top: pointer.y - newText.height / 2,
      // });
      canvas.setActiveObject(newText);
      canvas.renderAll();
    }
  });

  canvas.on("mouse:up", function () {
    setMode();
    reset();
    setType();
    canvas.setActiveObject(newText);
    canvas.renderAll();
    canvas.defaultCursor = "default";
    document.body.style.cursor = "default";
  });
};

export function addImg(src, canvas, left = 580, top = 250) {
  return new Promise((resolve, reject) => {
    fabric.Image.fromURL(
      src,
      (img) => {
        // Obtém o tamanho atual do canvas
        const realSize = getScreenSize(canvas.vptCoords.br);
        const [canvasWidth, canvasHeight] = realSize.split('x').map(Number);

        // Define uma resolução de referência, por exemplo, 1920x1080
        const referenceWidth = 1920;
        const referenceHeight = 1080;

        // Calcula o fator de escala baseado na relação entre a tela atual e a resolução de referência
        const scaleFactor = Math.min(canvasWidth / referenceWidth, canvasHeight / referenceHeight);

        // Ajusta a escala proporcionalmente para manter a proporção original da imagem
        const scaledWidth = img.width * scaleFactor;
        const scaledHeight = img.height * scaleFactor;

        // Aplica as dimensões escaladas e mantém a proporção
        img.set({
          left: left,
          top: top,
          scaleX: scaledWidth / img.width,
          scaleY: scaledHeight / img.height,
        });

        canvas.add(img);
        canvas.setActiveObject(img);
        canvas.renderAll();

        const imageData = {
          width: img.width,
          height: img.height,
          scaleX: img.scaleX,
          scaleY: img.scaleY,
          src: src,
        };

        resolve(imageData);
      },
      {
        crossOrigin: "Anonymous",
      }
    );
  });
}



// Function to crop the image into a circle
export function clipInCircle(img, canvas, radius = 200, left = 200, top = 200) {
  if (img) {
    const clipPath = new fabric.Circle({
      radius: radius,
      left: -left,
      top: -top,
      fill: "transparent",
    });
    img.set({
      clipPath: clipPath,
    });
    canvas.renderAll();
  }
}

export function canvasPanning(canvas, panningEnabled = false) {
  let isDragging = false;
  let lastX, lastY;
  if (panningEnabled) {
    canvas.discardActiveObject();
    canvas.defaultCursor = "move";
    canvas.forEachObject(function (object) {
      object.prevEvented = object.evented;
      object.prevSelectable = object.selectable;
      object.evented = false;
      object.selectable = false;
    });

    canvas.renderAll();
  } else {
    canvasPanningOff(canvas);
  }
  // when click
  canvas.on("mouse:down", function (options) {
    if (panningEnabled && !isDragging) {
      isDragging = true;
      const client = options.e;
      lastX = client.clientX;
      lastY = client.clientY;
    }
  });

  // when move
  canvas.on("mouse:move", function (options) {
    if (panningEnabled && isDragging) {
      const client = options.e;
      const deltaX = client.clientX - lastX;
      const deltaY = client.clientY - lastY;
      canvas.viewportTransform[4] += deltaX;
      canvas.viewportTransform[5] += deltaY;
      canvas.requestRenderAll();
      lastX = client.clientX;
      lastY = client.clientY;
    }
  });
  // when drop click
  canvas.on("mouse:up", function () {
    isDragging = false;
  });
}
// disable panning
export function canvasPanningOff(canvas) {
  canvas.forEachObject(function (object) {
    object.evented =
      object.prevEvented !== undefined ? object.prevEvented : object.evented;
    object.selectable =
      object.prevSelectable !== undefined
        ? object.prevSelectable
        : object.selectable;
  });
  canvas.defaultCursor = "default";
  canvas.off("mouse:up");
  canvas.off("mouse:down");
  canvas.off("mouse:move");

  canvas.discardActiveObject();
  const sel = new fabric.ActiveSelection(canvas.getObjects(), {
    canvas: canvas,
  });
  canvas.setActiveObject(sel);
  canvas.requestRenderAll();
  canvas.discardActiveObject();
}

export function canvasZoom(canvas, zoomFactor, zoomState) {
  const { zoom, setZoom } = zoomState;

  let newZoom = zoom * zoomFactor;
  setZoom(newZoom);

  if (newZoom > 0.01 && newZoom < 20) {
    canvas.setWidth(canvas.getWidth() * zoomFactor);
    canvas.setHeight(canvas.getHeight() * zoomFactor);
  }

  if (newZoom > 20) newZoom = 20;
  if (newZoom < 0.01) newZoom = 0.01;

  canvas.setZoom(newZoom);

  canvas.requestRenderAll();
  canvas.discardActiveObject();
  canvas.renderAll();
}

export function canvasCopy(canvas) {
  let clonedObj = null;
  canvas.getActiveObject().clone(function (cloned) {
    clonedObj = cloned;
  });
  return clonedObj;
}

export function canvasPaste(canvas, clipBoard) {
  // clone again, so you can do multiple copies.
  clipBoard.clone(function (clonedObj) {
    canvas.discardActiveObject();
    clonedObj.set({
      left: clonedObj.left + 30,
      top: clonedObj.top + 30,
      evented: true,
    });
    if (clonedObj.type === "activeSelection") {
      clonedObj.canvas = canvas;
      clonedObj.forEachObject(function (obj) {
        canvas.add(obj);
      });
      clonedObj.setCoords();
    } else {
      canvas.add(clonedObj);
    }
    clipBoard.top += 30;
    clipBoard.left += 30;
    canvas.setActiveObject(clonedObj);
    canvas.requestRenderAll();
  });
}

export function canvasKeyboardEvents(canvas) {
  let clipboard = null;
  if (canvas)
    document.addEventListener("keydown", function (e) {
      let key = e.keyCode || e.which;

      if ((e.ctrlKey || e.metaKey) && key === 90) {
        // Ctrl+Z (undo)
        canvas.undo();
      } else if ((e.ctrlKey || e.metaKey) && key === 67) {
        // Ctrl+C (copy)
        const activeObject = canvas.getActiveObject();
        if (activeObject && !activeObject.isEditing)
          clipboard = canvasCopy(canvas);
      } else if ((e.ctrlKey || e.metaKey) && key === 86 && clipboard) {
        // Ctrl+V (paste)
        const activeObject = canvas.getActiveObject();
        if ((activeObject && !activeObject.isEditing) || !activeObject) {
          canvasPaste(canvas, clipboard);
        }
      } else if (
        key === 46
        // || key === 8
      ) {
        // Delete
        const multipleObjs = canvas.getActiveObjects();
        multipleObjs.forEach(function (obj) {
          canvas.remove(obj);
        });
        canvas.discardActiveObject().renderAll();
      } else if (key === 38) {
        // move object up
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
          e.preventDefault();
          activeObject.set("top", activeObject.top - 5);
        }
        canvas.renderAll();
      } else if (key === 40) {
        // move object down
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
          e.preventDefault();
          activeObject.set("top", activeObject.top + 5);
        }
        canvas.renderAll();
      } else if (key === 37) {
        // move object left
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
          e.preventDefault();
          activeObject.set("left", activeObject.left - 5);
        }
        canvas.renderAll();
      } else if (key === 39) {
        // move object right
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
          e.preventDefault();
          activeObject.set("left", activeObject.left + 5);
        }
        canvas.renderAll();
      }
    });
}

export function canvasEventsControl(canvas) {
  canvas.on("object:scaling", function (obj) {
    if (obj.target && obj.target.height && obj.target.scaleY) {
      if (obj.target.hasOwnProperty("text")) {
        obj.target.scaleX = obj.target.scaleY;
        obj.target._clearCache();
        canvas.renderAll();
      }
    }
  });
}

// ------------------------------- shapes by click -------------------------------------

export const addTriangleOnclick = (canvas, stateV, setMode, reset, setType) => {
  const shapeColor = isBackgroundColorDark(
    canvas.backgroundColor,
    "#FFFFFF",
    "#000000"
  );
  canvas.defaultCursor = "crosshair";
  document.body.style.cursor = "crosshair";

  let triangle;
  let startPosition = {};

  canvas.on("mouse:down", function (event) {
    if (stateV) {
      const pointer = canvas.getPointer(event.e);
      startPosition.x = pointer.x;
      startPosition.y = pointer.y;

      triangle = new fabric.Triangle({
        left: pointer.x,
        top: pointer.y,
        width: 0,
        height: 0,
        fill: "",
        stroke: shapeColor,
        strokeWidth: 1,
        strokeUniform: true,
      });
    }
  });

  canvas.on("mouse:move", function (event) {
    if (!stateV || !triangle) return;
    const pointer = canvas.getPointer(event.e);
    const newWidth = Math.abs(pointer.x - startPosition.x);
    const newHeight = Math.abs(pointer.y - startPosition.y);

    triangle.set({
      width: newWidth,
      height: newHeight,
    });
    handleStartPositionByClick(startPosition, pointer, triangle);
    canvas.renderAll();
  });

  canvas.on("mouse:up", function (event) {
    const pointer = canvas.getPointer(event.e);
    if (startPosition.x === pointer.x) {
      triangle.set({
        width: 100,
        height: 100,
      });
    }

    canvas.add(triangle);
    canvas.setActiveObject(triangle);
    canvas.renderAll();

    setMode();
    setType();
    reset();
    canvas.setActiveObject(triangle);
    canvas.renderAll();
    canvas.defaultCursor = "default";
    document.body.style.cursor = "default";
  });
};

export const addSquareOnclick = (
  canvas,
  stateV,
  setMode,
  reset,
  setType,
  type
) => {
  const shapeColor = isBackgroundColorDark(
    canvas.backgroundColor,
    "#FFFFFF",
    "#000000"
  );
  canvas.defaultCursor = "crosshair";
  document.body.style.cursor = "crosshair";

  let square;
  let startPosition = {};

  canvas.on("mouse:down", function (event) {
    if (stateV) {
      const pointer = canvas.getPointer(event.e);
      startPosition.x = pointer.x;
      startPosition.y = pointer.y;

      const commonOptions = {
        left: pointer.x,
        top: pointer.y,
        width: 0,
        height: 0,
        fill: "",
        stroke: shapeColor,
        strokeWidth: 1,
        strokeUniform: true,
      };
      switch (type) {
        case "square_shape":
          square = new fabric.Rect(commonOptions);
          break;
        case "square_rounded_shape":
          square = new fabric.Rect({
            ...commonOptions,
            rx: 10,
            ry: 10,
          });
          break;
        default:
          square = new fabric.Rect(commonOptions);
      }
    }
  });

  if (type === "square-rounded") {
    square.on("scaling", function () {
      this.set({
        width: this.width * this.scaleX,
        height: this.height * this.scaleY,
        scaleX: 1,
        scaleY: 1,
      });
    });
  }

  canvas.on("mouse:move", function (event) {
    if (!stateV || !square) return;
    const pointer = canvas.getPointer(event.e);
    const newWidth = Math.abs(pointer.x - startPosition.x);
    const newHeight = Math.abs(pointer.y - startPosition.y);

    square.set({
      width: newWidth,
      height: newHeight,
    });
    handleStartPositionByClick(startPosition, pointer, square);
    canvas.renderAll();
  });

  canvas.on("mouse:up", function (event) {
    const pointer = canvas.getPointer(event.e);
    if (startPosition.x === pointer.x) {
      square.set({
        width: 100,
        height: 100,
      });
    }

    canvas.add(square);
    canvas.setActiveObject(square);
    canvas.renderAll();

    setMode();
    setType();
    reset();
    canvas.setActiveObject(square);
    canvas.renderAll();
    canvas.defaultCursor = "default";
    document.body.style.cursor = "default";
  });
};

export const addCircleOnclick = (
  canvas,
  stateV,
  setMode,
  reset,
  setType,
  type
) => {
  const shapeColor = isBackgroundColorDark(
    canvas.backgroundColor,
    "#FFFFFF",
    "#000000"
  );
  canvas.defaultCursor = "crosshair";
  document.body.style.cursor = "crosshair";

  let circle;
  let startPosition = {};

  canvas.on("mouse:down", function (event) {
    if (stateV) {
      const pointer = canvas.getPointer(event.e);
      startPosition.x = pointer.x;
      startPosition.y = pointer.y;

      const commonOptions = {
        left: pointer.x,
        top: pointer.y,
        radius: 50,
        fill: "",
        stroke: shapeColor,
        strokeWidth: 1,
        strokeUniform: true,
      };
      switch (type) {
        case "circle_shape":
          circle = new fabric.Circle(commonOptions);
          break;
        default:
          circle = new fabric.Circle(commonOptions);
      }
    }
  });

  canvas.on("mouse:move", function (event) {
    if (!stateV || !circle) return;
    const pointer = canvas.getPointer(event.e);
    let radius =
      Math.max(
        Math.abs(startPosition.y - pointer.y),
        Math.abs(startPosition.x - pointer.x)
      ) / 2;
    if (radius > circle.strokeWidth) {
      radius -= circle.strokeWidth / 2;
    }

    const deltaX = pointer.x - startPosition.x;
    const deltaY = pointer.y - startPosition.y;
    const scaleX = deltaX / circle.width;
    const scaleY = deltaY / circle.height;

    circle.set({ radius: radius, scaleX: scaleX, scaleY: scaleY });
    handleStartPositionByClick(startPosition, pointer, circle);
    canvas.renderAll();
  });

  canvas.on("mouse:up", function (event) {
    const pointer = canvas.getPointer(event.e);
    if (startPosition.x === pointer.x) {
      circle.set({
        radius: 50,
      });
    }

    canvas.add(circle);
    canvas.setActiveObject(circle);
    canvas.renderAll();

    setMode();
    setType();
    reset();
    canvas.setActiveObject(circle);
    canvas.renderAll();
    canvas.defaultCursor = "default";
    document.body.style.cursor = "default";
  });
};

export const addArrowOnclick = (
  canvas,
  stateV,
  setMode,
  reset,
  setType,
  type
) => {
  const shapeColor = isBackgroundColorDark(
    canvas.backgroundColor,
    "#FFFFFF",
    "#000000"
  );
  canvas.defaultCursor = "crosshair";
  document.body.style.cursor = "crosshair";

  let arrow;
  let startPosition = {};

  const arrowPoints = [
    { x: 0, y: 0 }, // Starting point
    { x: 40, y: 0 }, // Right end of the rod
    { x: 40, y: 20 }, // Top of arrow
    { x: 80, y: -20 }, // Center of arrowhead
    { x: 40, y: -60 }, // bottom of arrow
    { x: 40, y: -40 }, // Right end of the rod
    { x: 0, y: -40 }, // final point
  ];

  canvas.on("mouse:down", function (event) {
    if (stateV) {
      const pointer = canvas.getPointer(event.e);
      startPosition.x = pointer.x;
      startPosition.y = pointer.y;

      const commonOptions = {
        left: pointer.x,
        top: pointer.y,
        fill: "",
        stroke: shapeColor,
        strokeWidth: 1,
        strokeUniform: true,
        flipY: true,
      };

      switch (type) {
        case "arrow_shape":
          arrow = new fabric.Polygon(arrowPoints, commonOptions);
          break;
        default:
          arrow = new fabric.Polygon(arrowPoints, commonOptions);
      }
    }
  });

  canvas.on("mouse:move", function (event) {
    if (!stateV || !arrow) return;
    const pointer = canvas.getPointer(event.e);
    const newWidth = Math.abs(pointer.x - startPosition.x);
    const newHeight = Math.abs(pointer.y - startPosition.y);

    const deltaX = pointer.x - startPosition.x;
    const deltaY = pointer.y - startPosition.y;
    const scaleX = deltaX / arrow.width;
    const scaleY = deltaY / arrow.height;

    arrow.set({
      scaleX: scaleX,
      scaleY: scaleY,
    });
    handleStartPositionByClick(startPosition, pointer, arrow);
    canvas.renderAll();
  });

  canvas.on("mouse:up", function (event) {
    canvas.add(arrow);
    canvas.setActiveObject(arrow);
    canvas.renderAll();

    setMode();
    setType();
    reset();
    canvas.setActiveObject(arrow);
    canvas.renderAll();
    canvas.defaultCursor = "default";
    document.body.style.cursor = "default";
  });
};

export const addStarOnclick = (
  canvas,
  stateV,
  setMode,
  reset,
  setType,
  type
) => {
  const shapeColor = isBackgroundColorDark(
    canvas.backgroundColor,
    "#FFFFFF",
    "#000000"
  );
  canvas.defaultCursor = "crosshair";
  document.body.style.cursor = "crosshair";

  let star;
  let startPosition = {};

  const polygonCoords = [
    { x: 0, y: 0 }, // Starting point
    { x: 10, y: -30 },
    { x: 40, y: -30 },
    { x: 15, y: -50 },
    { x: 25, y: -80 },
    { x: 0, y: -60 }, // center of star
    { x: -25, y: -80 },
    { x: -15, y: -50 },
    { x: -40, y: -30 },
    { x: -10, y: -30 },
  ];

  canvas.on("mouse:down", function (event) {
    if (stateV) {
      const pointer = canvas.getPointer(event.e);
      startPosition.x = pointer.x;
      startPosition.y = pointer.y;

      const commonOptions = {
        left: pointer.x,
        top: pointer.y,
        fill: "",
        stroke: shapeColor,
        strokeWidth: 1,
        strokeUniform: true,
        flipY: true,
      };

      switch (type) {
        case "arrow_shape":
          star = new fabric.Polygon(polygonCoords, commonOptions);
          break;
        default:
          star = new fabric.Polygon(polygonCoords, commonOptions);
      }
    }
  });

  canvas.on("mouse:move", function (event) {
    if (!stateV || !star) return;
    const pointer = canvas.getPointer(event.e);
    const newWidth = Math.abs(pointer.x - startPosition.x);
    const newHeight = Math.abs(pointer.y - startPosition.y);

    const deltaX = pointer.x - startPosition.x;
    const deltaY = pointer.y - startPosition.y;
    const scaleX = deltaX / star.width;
    const scaleY = deltaY / star.height;

    star.set({
      scaleX: scaleX,
      scaleY: scaleY,
    });
    handleStartPositionByClick(startPosition, pointer, star);
    canvas.renderAll();
  });

  canvas.on("mouse:up", function (event) {
    canvas.add(star);
    canvas.setActiveObject(star);
    canvas.renderAll();

    setMode();
    setType();
    reset();
    canvas.setActiveObject(star);
    canvas.renderAll();
    canvas.defaultCursor = "default";
    document.body.style.cursor = "default";
  });
};

