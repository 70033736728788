/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Card } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";
import { useState, useEffect } from "react";
import MDButton from "shared/components/MDButton";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { initialValuesEditBanner } from "../schemas/initial_values";
import { validationsEdit } from "../schemas/validations";
import toastError from "shared/components/snackbar/error/toastError";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import bannersService from "shared/services/banners/banners.service";
import FormField from "shared/components/form_field";
import { getImageDimensions } from "utils";
import { errorMultipleMsgToast } from "utils/errorStatusCode";
import MDProgress from "shared/components/MDProgress";

function BannersEdit() {
  const { uuid } = useParams();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();
  const [imgDimensions, setImgDimensions] = useState("");
  const [loadingData, setLoadingData] = useState(false);

  const loadBanner = async () => {
    try {
      setLoadingData(true);
      const res = await bannersService.read(uuid);
      if (res) {
        const dimensions = await getImageDimensions(res.file_link);
        setImgDimensions(`${dimensions.width} x ${dimensions.height}`);
      }
      setInitialValuesFromApi(res);
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      const data = {
        name: values.name,
      };
      const res = await bannersService.edit(data, uuid);

      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess(
        "Banner editado com sucesso",
        "",
        true,
        "/banners",
        "Redirecionar para banners"
      );
    } catch (e) {
      errorMultipleMsgToast(e);
    }
  };

  const handleDowload = () => {
    const { file_link } = initialValuesFromApi;
    const link = document.createElement("a");
    link.href = file_link;
    link.download = "imagem.jpg";
    link.click();
  };

  useEffect(() => {
    loadBanner();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValuesFromApi ?? initialValuesEditBanner}
        validationSchema={validationsEdit[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const { name: nameV, file_link: file_linkV } = values;

          return (
            <Form id="user-edit-form" autoComplete="off">
              <Card id="basic-info" sx={{ overflow: "visible", padding: 3 }}>
                <MDBox display="flex">
                  <MDBox>
                    <MDTypography variant="h5">Editar Banner</MDTypography>
                  </MDBox>

                  <MDBox ml="auto">
                    <MDButton
                      onClick={handleDowload}
                      color="primary"
                      type="button"
                      size="medium"
                    >
                      download
                    </MDButton>
                  </MDBox>
                </MDBox>

                <MDBox mt={2}>
                  <FormField
                    name="name"
                    label="Nome *"
                    type="text"
                    value={nameV}
                  />
                </MDBox>

                <MDBox>
                  <MDTypography variant="body2">Banner</MDTypography>
                  <MDTypography variant="body2">
                    Dimensões: {imgDimensions}
                  </MDTypography>
                  {loadingData ? (
                    <MDProgress
                      variant="gradient"
                      variantProgress="indeterminate"
                      color="primary"
                    />
                  ) : (
                    <MDBox
                      sx={{
                        overflowX: "auto",
                        width: "100%",
                        border: "1px solid rgba(0, 0, 0, 0.16)",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        padding: "10px",
                      }}
                    >
                      <img
                        style={{
                          objectFit: "cover",
                          border: "1px solid rgba(0, 0, 0, 0.16)",
                          maxWidth: "100%",
                          maxHeight: "300px",
                        }}
                        src={file_linkV}
                        alt="Imagem do banner"
                      />
                    </MDBox>
                  )}
                </MDBox>

                <MDBox py={3} width="12rem" ml="auto">
                  <MDButton
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                    size="large"
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default BannersEdit;
