import * as Yup from "yup";

const validations = [
  // Yup.object().shape({
  //   // modelHeight: Yup.number().required("A altura é obrigatória"),
  // }),
  Yup.object().shape({
    bannerName: Yup.string().required("O nome é obrigatório"),
  }),
];

export const validationsEdit = [
  Yup.object().shape({
    name: Yup.string().required("O nome é obrigatório"),
  }),
];

export default validations;
