// local
//const base_url = "http://127.0.0.1:9000";

// dev
// const base_url = "https://api.dev.bannerfy.com.br";

// homol
// const base_url = "https://api.hml.bannerfy.com.br";

// prod
const base_url = "https://bannerfy-api-e2f92618249b.herokuapp.com";

export default base_url;
