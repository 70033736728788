const mockAdmin = {
  first_name: "fulano",
  last_name: "da Rocha",
  phone: "(11)11111-1111",
  email: "teste@teste.com",
  password:"",
  role: 0,
  status: true,
};

export default mockAdmin;
