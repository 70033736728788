import React from "react";
import ic_upload_icon from "assets/images/ic_document_icon.png";
import pdf_icon from "assets/images/pdf_icon.png";
import png_icon from "assets/images/png_icon.png";
import jpg_icon from "assets/images/jpg_icon.png";
import jpeg_icon from "assets/images/jpeg_icon.png";
import {
  DropzoneFilesContainer,
  DropzoneFiles,
  ImgFile,
  InputFile,
  // DivFile,
  // ImgFile2,
  // ButtonDiv,
  // PDFView,
  // LengthInfo,
} from "./style";

import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { ErrorMessage } from "formik";
import CircularProgressWithLabel from "../loader/circularProgressWithLabel";

export default function DropZoneFiles({
  onChange,
  accept,
  NameDescription,
  fieldName = "file",
  required = false,
  value,
  loadingValue = 0,
}) {
  const loadSrcByType = (value) => {
    if (value && value.type.includes("pdf")) return pdf_icon;
    if (value && value.type.includes("png")) return png_icon;
    if (value && value.type.includes("jpg")) return jpg_icon;
    if (value && value.type.includes("jpeg")) return jpeg_icon;

    return ic_upload_icon;
  };

  const nameFile = value ? value.name : null;
  const imgSrc = value ? loadSrcByType(value) : ic_upload_icon;

  return (
    <MDBox sx={DropzoneFilesContainer}>
      <MDBox sx={DropzoneFiles}>
        {required && (
          <MDTypography variant="caption" color="secondary" align="center">
            Informação obrigatória <strong style={{ color: "red" }}>*</strong>
          </MDTypography>
        )}
        <input
          style={InputFile}
          accept={accept}
          type="file"
          onChange={onChange}
        />
        {loadingValue ? (
          <MDBox m={3}>
            <CircularProgressWithLabel value={loadingValue} size={80} />
          </MDBox>
        ) : (
          <MDBox sx={ImgFile} component="img" src={imgSrc} />
        )}

        {nameFile ?? NameDescription}
      </MDBox>
      <MDBox>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          mt={0.75}
        >
          <ErrorMessage name={fieldName} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}
