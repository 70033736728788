// image items
import {
  AlignTextCenter,
  AlignTextLeft,
  AlignTextRight,
  CanvasDrawColor,
  CanvasDrawStrokeWidth,
  ClipImageInCircle,
  CloneCanvasObject,
  DeleteItem,
  PriorityDown,
  PriorityUp,
  ShapeColor,
  TextBold,
  TextColor,
  TextFontFamily,
  TextFontSize,
  TextItalic,
  TextUnderLine,
} from "./index";

export function dynamicMenuItemsArray(dataItem, menutype) {
  const type = menutype;
  return [
    {
      types: [
        "text",
        "textbox",
        "circle",
        "image",
        "path",
        "rect",
        "triangle",
        "polygon",
      ],
      component: <PriorityUp dataItem={dataItem} />,
    },
    {
      types: [
        "text",
        "textbox",
        "circle",
        "image",
        "path",
        "rect",
        "triangle",
        "polygon",
      ],
      component: <PriorityDown dataItem={dataItem} />,
    },
    // text and textbox
    {
      types: ["text", "textbox"],
      component: <TextColor dataItem={dataItem} />,
    },
    {
      types: ["text", "textbox"],
      component: <TextFontSize dataItem={dataItem} />,
    },
    {
      types: ["text", "textbox"],
      component: <TextFontFamily dataItem={dataItem} />,
    },
    {
      types: ["text", "textbox"],
      component: <AlignTextLeft dataItem={dataItem} />,
    },
    {
      types: ["text", "textbox"],
      component: <AlignTextCenter dataItem={dataItem} />,
    },
    {
      types: ["text", "textbox"],
      component: <AlignTextRight dataItem={dataItem} />,
    },
    {
      types: ["text", "textbox"],
      component: <TextBold dataItem={dataItem} />,
    },
    {
      types: ["text", "textbox"],
      component: <TextItalic dataItem={dataItem} />,
    },
    {
      types: ["text", "textbox"],
      component: <TextUnderLine dataItem={dataItem} />,
    },
    // images
    {
      types: ["image-test"],
      component: <ClipImageInCircle dataItem={dataItem} />,
    },
    // paths, lines and shapes
    {
      types: ["path", "rect", "circle", "triangle", "polygon"],
      component: (
        <CanvasDrawColor
          dataItem={dataItem}
          label={type !== "path" ? "Contorno" : ""}
        />
      ),
    },
    {
      types: ["path", "rect", "circle", "triangle", "polygon", "polygon"],
      component: (
        <CanvasDrawStrokeWidth
          dataItem={dataItem}
          label={type !== "path" ? "Espessura" : ""}
        />
      ),
    },
    // shapes
    {
      types: ["rect", "circle", "triangle", "polygon"],
      component: <ShapeColor dataItem={dataItem} label={"Preenchimento"} />,
    },
    {
      types: [
        "text",
        "textbox",
        "circle",
        "image",
        "path",
        "rect",
        "triangle",
        "polygon",
        "multiple",
      ],
      component: <CloneCanvasObject dataItem={dataItem} />,
    },
    {
      types: [
        "text",
        "textbox",
        "circle",
        "image",
        "path",
        "rect",
        "triangle",
        "polygon",
        "multiple",
      ],
      component: <DeleteItem dataItem={dataItem} />,
    },
  ];
}
