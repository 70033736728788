import { useEffect, useState } from "react";
import { Card, Icon, useTheme } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";

import CalibrationForm from "./components/calibrationForm/calibrationForm";
import XMLForm from "./components/xmlForm/xmlForm";
import CircularIndeterminate from "shared/components/loader/circularIndeterminate";

function CalibrationXML() {
  const theme = useTheme();
  const { palette } = theme;
  const [XMLCalibrated, setXMLCalibrated] = useState();
  const [calibratedData, setCalibratedData] = useState(null);
  const [isMounted, setIsMounted] = useState(true);
  console.log("bateu aqui")
  useEffect(() => {
    setXMLCalibrated();
    return () => {
      setIsMounted(false);
    };
  }, []);

  const handleXmlCalibrated = (data) => {
    if (data) { // Verifica se 'data' não é undefined
      setCalibratedData(data);
      console.log("data => ", data)
      setXMLCalibrated(true); // Atualiza o estado de XMLCalibrated
    } else {
      console.log("negativo => ", data)
      setXMLCalibrated(false); // Define como false se 'data' for undefined
    }
  };

  return (
    <MDBox id="basic-info" sx={{ overflow: "visible", width: "100%" }}>
      <MDBox mb={2} display="flex" alignItems="center">
        <MDTypography variant="h5">Calibrar XML</MDTypography>
        {XMLCalibrated === true && (
          <MDBox display="flex" alignItems="center">
            <Icon
              sx={{ ml: 2, mr: 1, color: palette.success.main }}
              color="success"
            >
              check_circle
            </Icon>
            <MDTypography variant="body2" component="span">
              XML calibrado
            </MDTypography>
          </MDBox>
        )}
        {XMLCalibrated === false && (
          <MDBox display="flex" alignItems="center">
            <Icon sx={{ ml: 2, mr: 1 }} color="error">
              cancel
            </Icon>
            <MDTypography variant="body2" component="span">
              XML precisa ser calibrado
            </MDTypography>
          </MDBox>
        )}

        {XMLCalibrated === undefined && (
          <MDBox display="flex" alignItems="center" sx={{ ml: 2, mr: 1 }}>
            <CircularIndeterminate colorel={theme.palette.primary.main} />
          </MDBox>
        )}
      </MDBox>

        <XMLForm 
          xmlCalibrated={{ setXMLCalibrated }} 
          onXmlCalibrated={handleXmlCalibrated} // Certifique-se de que esta propriedade está correta
        />
        <CalibrationForm data={calibratedData} />
    </MDBox>
  );
}

export default CalibrationXML;
