import { Card } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";
import { useState } from "react";
import MDButton from "shared/components/MDButton";
import { Form, Formik } from "formik";
import FormField from "shared/components/form_field";
import initialValues from "../schemas/initial_values";
import validations from "../schemas/validations";
import AdminsService from "shared/services/admins/admins.service";
import { maskPhone } from "utils/masks";
import toastError from "shared/components/snackbar/error/toastError";
import mockAdmin from "../mocks/mockAdmin";
import { formFieldDDIAdornment } from "shared/components/form_field/form_field_configs";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";

function AdminsCreate() {
  const [mockData, setMockData] = useState();

  const handleSubmit = async (values, actions) => {
    try {
      await AdminsService.create(values);
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess(
        "Administrador criado com sucesso",
        "",
        true,
        "/admins",
        "Redirecionar para Administradores"
      );
    } catch (e) {
      toastError(e.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={mockData ?? initialValues}
        validationSchema={validations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const {
            first_name: first_nameV,
            last_name: last_nameV,
            phone: phoneV,
            email: emailV,
          } = values;
          return (
            <Form id="user-create-form" autoComplete="off">
              <Card id="basic-info" sx={{ overflow: "visible" }}>
                <MDBox p={3}>
                  <MDTypography variant="h5">Novo Administrador</MDTypography>
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="first_name"
                    label="Nome *"
                    type="text"
                    value={first_nameV}
                    error={errors.first_name && touched.first_name}
                    success={first_nameV.length > 0 && !errors.first_name}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="last_name"
                    label="Sobrenome *"
                    type="text"
                    value={last_nameV}
                    error={errors.last_name && touched.last_name}
                    success={last_nameV.length > 0 && !errors.last_name}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="phone"
                    label="Telefone *"
                    type="text"
                    value={phoneV}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("phone", maskPhone(value));
                    }}
                    InputProps={{
                      startAdornment: formFieldDDIAdornment(),
                    }}
                    error={errors.phone && touched.phone}
                    success={phoneV.length > 0 && !errors.phone}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="email"
                    label="Email *"
                    type="email"
                    value={emailV}
                    error={errors.email && touched.email}
                    success={emailV.length > 0 && !errors.email}
                  />
                </MDBox>

                <MDBox display="flex" gap="5px" py={3} px={3} ml="auto">
                  <MDButton
                    color="secondary"
                    type="button"
                    size="large"
                    fullWidth
                    onClick={() => setMockData(mockAdmin)}
                  >
                    Preencher
                  </MDButton>
                  <MDButton
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                    size="large"
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default AdminsCreate;
