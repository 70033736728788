/* eslint-disable no-unused-vars */
import { Card, Grid } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";
import { useState } from "react";
import MDButton from "shared/components/MDButton";
import { Form, Formik } from "formik";
import FormField from "shared/components/form_field";
import initialValues from "../schemas/initial_values";
import validations from "../schemas/validations";
import TemplateService from "shared/services/templates/templates.service";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";

import styles from "./styles.module.css";
import FormFieldFileButton from "shared/components/form_field_file_button";
import { isBackgroundColorDark } from "utils";
import { getImageDimensions } from "utils";
import { errorMultipleMsgToast } from "utils/errorStatusCode";
import { useNavigate } from "react-router-dom";

function modelBackgroundSet(background_color, width, height) {
  let newWidth = "100%";
  let newHeight = "100%";
  if (width > height) {
    newWidth = "100%";
    newHeight = "400px";
  } else if (width < height) {
    newHeight = "100%";
    newWidth = "400px";
  } else {
    newHeight = "400px";
    newWidth = "400px";
  }
  return {
    backgroundColor: background_color,
    width: newWidth,
    height: newHeight,
    minHeight: "400px",
    margin: 0,
  };
}

function modelColorTextSet(background_color) {
  return {
    color: isBackgroundColorDark(background_color),
  };
}

function TemplatesCreate() {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState();

  const handleSelectedImage = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setFieldValue("background_image", file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        setSelectedImage(e.target.result);
        const dimensions = await getImageDimensions(e.target.result);
        setFieldValue("width", dimensions.width);
        setFieldValue("height", dimensions.height);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (values, actions) => {
    const { name, height, width, background_color, background_image } = values;
    try {
      const formD = new FormData();
      formD.append("name", name);
      formD.append("height", height);
      formD.append("width", width);
      if (background_color) formD.append("background_color", background_color);
      if (background_image) formD.append("background_image", background_image);
      toastSuccess("Aguarde o termino da operação", "Aguarde");
      const res = await TemplateService.create(formD);
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess(
        "Template criado com sucesso",
        "",
        true,
        "/templates",
        "Redirecionar para Modelos"
      );
    } catch (e) {
      errorMultipleMsgToast(e);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValues}
        validationSchema={validations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
          setTouched,
          setSubmitting,
          resetForm,
        }) => {
          const {
            name: nameV,
            height: heightV,
            width: widthV,
            background_color: background_colorV,
            background_image: background_imageV,
          } = values;
          return (
            <Form id="user-create-form" autoComplete="off">
              <Card id="basic-info" sx={{ overflow: "visible", padding: 3 }}>
                <MDBox mb={3}>
                  <MDTypography variant="h5">Novo Modelo</MDTypography>
                </MDBox>

                <MDBox px={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} className={styles.menuAside}>
                      <MDBox mb={2}>
                        <FormField
                          name="name"
                          label="Nome do modelo *"
                          fieldVariant="outlined"
                          type="text"
                          value={nameV}
                        />
                      </MDBox>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            disabled={!!background_imageV}
                            name="width"
                            label="Largura *"
                            fieldVariant="outlined"
                            type="number"
                            value={widthV}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            disabled={!!background_imageV}
                            name="height"
                            label="Altura *"
                            fieldVariant="outlined"
                            type="number"
                            value={heightV}
                          />
                        </Grid>
                      </Grid>

                      <MDBox>
                        <MDTypography mb={2} variant="h5">
                          Modelo novo
                        </MDTypography>
                        <MDBox>
                          <FormField
                            fieldShrink
                            name="background_color"
                            size="50%"
                            label="Cor do modelo"
                            fieldVariant="outlined"
                            type="color"
                            value={background_colorV}
                            className={styles.formFieldSmall}
                            onChange={(event) => {
                              const { value } = event.target;
                              if (value) {
                                setFieldValue("background_image", null);
                                setFieldValue("background_color", value);
                                setSelectedImage();
                              }
                            }}
                          />
                        </MDBox>
                        <MDTypography mb={2} variant="h5">
                          ou
                        </MDTypography>
                        <FormFieldFileButton
                          name="fileModel"
                          accept=".jpg, .jpeg, .png, .webp"
                          label="Upload de imagem"
                          size="medium"
                          fullWidth
                          onChange={(event) => {
                            const { files } = event.target;
                            if (files[0]) {
                              setFieldValue("background_color", "#ffffff");
                              handleSelectedImage(event, setFieldValue);
                            }
                          }}
                        />
                      </MDBox>

                      <MDBox mb={2}>
                        <MDButton
                          color="primary"
                          size="medium"
                          fullWidth
                          onClick={() => {
                            resetForm();
                            setSelectedImage("");
                          }}
                        >
                          RESETAR CONFIGURAÇÕES
                        </MDButton>
                      </MDBox>
                      <MDBox mb={2}>
                        <MDButton
                          disabled={isSubmitting}
                          type="submit"
                          color="primary"
                          size="medium"
                          fullWidth
                        >
                          SALVAR
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <MDButton
                          variant="gradient"
                          color="error"
                          size="medium"
                          fullWidth
                          onClick={() => {
                            navigate("/templates", { replace: true });
                          }}
                        >
                          cancelar
                        </MDButton>
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                      <MDBox className={styles.modelBoxContainer}>
                        <MDBox
                          sx={modelBackgroundSet(
                            background_colorV,
                            widthV,
                            heightV
                          )}
                          className={styles.modelBox}
                        >
                          {selectedImage ? (
                            <img
                              className={styles.imgModel}
                              src={selectedImage}
                              alt="Imagem selecionada"
                            />
                          ) : (
                            <MDBox sx={modelColorTextSet(background_colorV)}>
                              MODELO
                            </MDBox>
                          )}
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                {/* 
                <MDBox display="flex" gap="5px" py={3} px={3} ml="auto">
                  <MDButton
                    color="secondary"
                    type="button"
                    size="medium"
                    fullWidth
                    onClick={() => setMockData(mockTemplate)}
                  >
                    Preencher
                  </MDButton>
                  <MDButton
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                    size="medium"
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox> */}
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default TemplatesCreate;
