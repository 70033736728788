const initialValues = {
  modelName: "",
  modelHeight: 300,
  modelWidth: 800,
  modelColor: "#ffffff",
  fileModel: "",
  xmlFile: "",
  xmlLink: "",
  xmlId: "",
  bannerModel: "#ffffff",
  bannerName: "",
  bannerFile: "",
};

export const initialValuesEditBanner = {
  name: "",
  file_link: ""
};

export default initialValues;
