/* eslint-disable no-unused-vars */
import {
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemButton,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDTypography from "shared/components/MDTypography";

// canvas
import {
  addImg,
  addMultipleText,
} from "./../../../components/canvasFunctions/index";
import calibrationService from "shared/services/calibration/calibration.service";
import toastError from "shared/components/snackbar/error/toastError";

// style
import styles from "./styles.module.css";
import removeBackgroundService from "shared/services/removeBackground/removeBackground.service";
import LinearIndeterminate from "shared/components/loader/linearIndeterminate";

import dragNDropService from "shared/services/dragNDrop";
import FormfieldMemo from "shared/components/form_field/form_field_memo";
import CircularIndeterminate from "shared/components/loader/circularIndeterminate";
import { base64StringToBlob } from "utils";
import { currencyMask } from "utils/masks";
import { errorMultipleMsgToast } from "utils/errorStatusCode";
import { getImagePosition } from "../../../components/canvasFunctions/styleBySize"

function BannerMenuAside({ formData, canvasData, removeBackground }) {
  const theme = useTheme();
  const { palette, typography } = theme;
  const { values, touched, errors, isSubmitting, setFieldValue, handleBlur } =
    formData;
  const {
    xmlId: xmlIdV,
    bannerModel: bannerModelV,
    bannerName: bannerNameV,
  } = values;
  // canvas
  const { canvas, setCanvas } = canvasData;
  // remove background
  const {
    isRemoveBackground,
    setIsRemoveBackground,
    removeBackgroundLoading,
    setRemoveBackgroundLoading,
  } = removeBackground;

  const [xmlFromApi, setXmlFromApi] = useState();
  const [calibrationFromApi, setCalibrationFromApi] = useState();

  const [xmlItems, setXmlItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [isSearchProduct, setIsSearchProduct] = useState(false);

  const handleListItemToggle = (value) => () => {
    const currentIndex = selectedItems.indexOf(value);
    if (currentIndex === -1) {
      setSelectedItems([...selectedItems, value]);
    } else {
      const filteredItems = selectedItems.filter((v) => v !== value);
      setSelectedItems(filteredItems);
    }
  };

  const handleRemoveBackground = async (imgSrc, type) => {
    setRemoveBackgroundLoading(true);
    const formD = new FormData();
    if (type === "base64") {
      const blob = base64StringToBlob(imgSrc);
      formD.append("image_file", blob);
    } else {
      formD.append("image_link", imgSrc);
    }

    const res = await removeBackgroundService.create(formD);
    let imagePos = getImagePosition(canvas.vptCoords.br)
    addImg(res, canvas, imagePos.left, imagePos.top);
    setIsRemoveBackground(false);
    setRemoveBackgroundLoading(false);
  };

  const handleAddItem = async () => {
    let type = ''
    let font = 'Montserrat'
    let installmentValue = 0;

    for (let value of selectedItems) {
      if (value.startsWith("R$")) {
        type = 'price';
        // Check if value already contains a comma
        value = value.includes(",") ? value : `${value},00`;
        // Remove "R$", convert to number, divide by 5, and store in installmentValue
        const numericValue = parseFloat(value.replace("R$", "").replace(",", ".").trim());
        installmentValue = (numericValue / 5).toFixed(2).replace(".", ",");
        addMultipleText(canvas, 'COMPRE JÁ', font, 'buyNow')
        addMultipleText(canvas, `5x de R$ ${installmentValue}`, font, 'times')
      } else {
        type = 'title';
      }

      if (value.includes("data:")) {
        if (isRemoveBackground) {
          handleRemoveBackground(value, "base64");
        } else {
          let imagePos = getImagePosition(canvas.vptCoords.br)
          await addImg(value, canvas, imagePos.left, imagePos.top);
          canvas.renderAll();
        }
      } else {
        addMultipleText(canvas, value, font, type); 
        canvas.renderAll();
      }

    }
    setSelectedItems([]);
  };

  const handleGetItem = async () => {
    try {
      setSelectedItems([]);
      setIsSearchProduct(true);
      const res = await calibrationService.readerXML(xmlIdV);
      if (res) {
        const fieldsToRemove = [
          "id",
          "user_id",
          "product_key",
          "pid",
          "category",
          "description",
        ];

        const newObj = Object.keys(calibrationFromApi)
          .filter((field) => !fieldsToRemove.includes(field))
          .reduce((acc, cur) => {
            acc[cur] = calibrationFromApi[cur];
            return acc;
          }, {});

        const objectFiltered = {};
        for (const key in newObj) {
          if (res.hasOwnProperty(newObj[key])) {
            objectFiltered[key] = res[newObj[key]];
          }
        }

        const arrayValues = Object.entries(objectFiltered).map(([k, v]) => {
          if (k === "price") {
            if (v.includes(" BRL")) return `R$ ${currencyMask(v)}`;
          }
          if (k.startsWith("installment")) {
            return `${
              objectFiltered["installment_month"]
            }x de R$ ${currencyMask(objectFiltered["installment_price"])}`;
          }
          return v;
        });

        const uniqueValues = [...new Set(arrayValues)];

        setXmlItems(uniqueValues);
        if (uniqueValues) setIsSearchProduct(false);
      }
    } catch (e) {
      errorMultipleMsgToast(e);
    } finally {
      setIsSearchProduct(false);
    }
  };

  const loadCalibration = async () => {
    try {
      const data = await calibrationService.getCalibration();
      if (data) setCalibrationFromApi(data);
    } catch (e) {
      if (e.message === "Calibragem não registrada")
        toastError(e.message, "", true, "/calibration", "Calibrar agora");
      else toastError(e.message);
    }
  };

  useEffect(() => {
    loadCalibration();
    return () => {
      setCalibrationFromApi();
    };
  }, []);

  return (
    <Grid item xs={12} sm={3} maxWidth="270px" className={styles.menuAside}>
      <MDBox mb={2}>
        <MDTypography variant="h5">Novo Banner</MDTypography>
      </MDBox>
      <MDBox mb={2}>
        <FormfieldMemo
          name="bannerName"
          label="Nome do banner *"
          fieldVariant="outlined"
          type="text"
          value={bannerNameV}
        />
      </MDBox>
      <MDBox mb={2}>
        <FormfieldMemo
          name="xmlId"
          label="GTIN ou ID *"
          fieldVariant="outlined"
          type="text"
          value={xmlIdV}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDButton
          variant="gradient"
          color="info"
          fullWidth
          onClick={handleGetItem}
          disabled={isSearchProduct}
        >
          {isSearchProduct ? <CircularIndeterminate /> : "Buscar"}
        </MDButton>
      </MDBox>
      {xmlItems.length > 0 && (
        <MDBox>
          <MDTypography variant="h5">Resultado do XML</MDTypography>
          <MDBox>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRemoveBackground}
                  onChange={(e) => setIsRemoveBackground(e.target.checked)}
                  color="primary"
                />
              }
              label="Remover fundo de imagem"
              // labelPlacement="top"
            />
          </MDBox>
          {removeBackgroundLoading && (
            <MDBox>
              <LinearIndeterminate />
            </MDBox>
          )}
          <MDBox className={styles.XMLItemsContainer}>
            <List dense className={styles.list}>
              {xmlItems.map((value, i) => {
                const labelId = `xml-${value}-${i}`;
                return (
                  <ListItem
                    key={i}
                    secondaryAction={
                      <Checkbox
                        edge="start"
                        onChange={handleListItemToggle(value)}
                        checked={selectedItems.includes(value)}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton onClick={handleListItemToggle(value)}>
                      {value.includes("data:") ? (
                        <img
                          src={value}
                          alt="img-xml"
                          draggable
                          onDragStart={(e) => dragNDropService.drag(e)}
                          id={`img-${i}`}
                          className={styles.listImg}
                          loading="lazy"
                        />
                      ) : (
                        <MDTypography
                          id={labelId}
                          draggable
                          onDragStart={(e) => dragNDropService.drag(e)}
                          className={styles.listText}
                        >
                          {value}
                        </MDTypography>
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </MDBox>
          <MDBox display="flex" justifyContent="center" mb={2}>
            <MDButton variant="gradient" color="info" onClick={handleAddItem}>
              Adicionar
            </MDButton>
          </MDBox>
        </MDBox>
      )}
    </Grid>
  );
}
export default BannerMenuAside;
