export const handleStartPositionByClick = (
  startPosition,
  currentPosition,
  fabricObj
) => {
  if (startPosition.x > currentPosition.x) {
    fabricObj.set({ originX: "left" });
  } else {
    fabricObj.set({ originX: "left" });
  }
  if (startPosition.y > currentPosition.y) {
    fabricObj.set({ originY: "bottom" });
  } else {
    fabricObj.set({ originY: "top" });
  }
};
