/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import PropTypes from "prop-types";

import {
  Card,
  Divider,
  Grid,
  Icon,
  IconButton,
  Modal,
  Slide,
  Tab,
  Tabs,
} from "@mui/material";

import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";

import styles from "./styles.module.css";
import { useTheme } from "@emotion/react";
import initialValues from "./schemas/initial_values";
import validations from "./schemas/validations";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import TemplateService from "shared/services/templates/templates.service";
import toastError from "shared/components/snackbar/error/toastError";
import { Form, Formik } from "formik";
import FormField from "shared/components/form_field";
import FormFieldFileButton from "shared/components/form_field_file_button";
import MDButton from "shared/components/MDButton";
import { useNavigate } from "react-router-dom";
import localStorageService from "shared/services/localStorage";
import StorageTypes from "shared/services/localStorage/storageTypes";

import { getImageDimensions } from "utils";
import DropZoneFiles from "shared/components/dropzoneFiles";
import fontsService from "shared/services/fonts/fonts.service";

const tabs = ["Recomendados", "Social"];

const fontAccept =
  ".abf, .afm, .bco, .bdf, .bez, .cfn, .chr, .dfont, .dwf, .e24, .eot, .fli, .fon, .fot, .fnt, .gf, .inf, .L30, .mf, .mgf, .mmm, .odt, .otf, .otl, .p9, .p24, .pcf, .pfa, .pfb, .pfm, .pk, .pl, .ps, .psf, .pso, .pxl, .qfm, .sfl, .sfp, .sfs, .snf, .spd, .suit, .tdf, .tfm, .ttc, .ttf, .vf, .vpl, .woff, .woff2";

function FontsCreateModal({ open, onClose }) {
  const theme = useTheme();

  const [isUploadingDoc, setIsUploadingDoc] = useState(false);
  const [state, setState] = useState(0);

  const [filefontName, setFileFontName] = useState("");

  const handleSelectedFile = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setFileFontName(file.name);
    }
  };

  const handleSubmit = async (values, actions) => {
    const { name, font } = values;
    try {
      const font_split = font.name.split(".");
      const font_mime = font_split[font_split.length - 1];
      const blob = new Blob([font], { type: `font/${font_mime}` });
      const formD = new FormData();
      formD.append("name", name);
      formD.append("font", blob);

      toastSuccess("Aguarde o termino da operação", "Aguarde");
      const res = await fontsService.create(formD);
      if (res) setIsUploadingDoc(false);
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess("Fonte criada com sucesso", "");
      onClose();
    } catch (e) {
      toastError(e.message);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <MDBox className={styles.modal}>
        <MDBox className={styles.modalHeader}>
          <Grid container>
            <Grid xs={11} sm={12} item>
              <MDTypography align="center">Adicionar fonte</MDTypography>
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              position="absolute"
              style={{ right: 5, top: 5 }}
            >
              <MDBox display="flex" justifyContent="flex-end">
                <IconButton size="small" onClick={onClose}>
                  <Icon fontSize="small" align="right">
                    close
                  </Icon>
                </IconButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Divider sx={{ background: theme.palette.dark.main, m: 0 }} />
        <MDBox className={styles.modelContainer}>
          <Formik
            initialValues={initialValues}
            validationSchema={validations[0]}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleBlur,
              setTouched,
              setSubmitting,
              resetForm,
            }) => {
              const { name: nameV, font: fontV } = values;
              return (
                <Form
                  id="template-form"
                  autoComplete="off"
                  style={{ width: "100%" }}
                >
                  <MDBox mb={2}>
                    <FormField
                      name="name"
                      label="Nome da fonte *"
                      fieldVariant="outlined"
                      type="text"
                      value={nameV}
                    />
                  </MDBox>

                  {/* 
                  <MDBox>
                    {filefontName && (
                      <MDTypography variant="body2">
                        Fonte selecionada: {filefontName}
                      </MDTypography>
                    )}
                    <FormFieldFileButton
                      name="fileModel"
                      accept="*"
                      label="Upload de fonte"
                      fullWidth
                      onChange={(event) => {
                        const { files } = event.target;
                        if (files[0]) {
                          setFieldValue("font", files[0]);
                          handleSelectedFile(event, setFieldValue);
                        }
                      }}
                    />
                  </MDBox> */}

                  <MDBox>
                    <DropZoneFiles
                      onChange={(e) => {
                        const { files } = e.target;
                        if (files[0]) {
                          setFieldValue("font", files[0]);
                          handleSelectedFile(e, setFieldValue);
                        }
                      }}
                      accept={fontAccept}
                      NameDescription="Nome da fonte"
                      fieldName="font"
                      required
                      value={fontV}
                      loadingValue={0}
                    />
                  </MDBox>

                  <MDBox>
                    <MDButton
                      disabled={isSubmitting}
                      type="submit"
                      color="primary"
                      size="medium"
                      fullWidth
                    >
                      salvar
                    </MDButton>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

FontsCreateModal.defaultProps = {
  onClose: null,
};

FontsCreateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default FontsCreateModal;
