/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import PropTypes from "prop-types";

import {
  Card,
  Divider,
  Grid,
  Icon,
  IconButton,
  Modal,
  Slide,
  Tab,
  Tabs,
} from "@mui/material";

import MDBox from "shared/components/MDBox";
import MDTypography from "./../../../../components/MDTypography/index";

import styles from "./styles.module.css";
import { useTheme } from "@emotion/react";
import initialValues from "./schemas/initial_values";
import validations from "./schemas/validations";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import TemplateService from "shared/services/templates/templates.service";
import toastError from "shared/components/snackbar/error/toastError";
import { Form, Formik } from "formik";
import FormField from "shared/components/form_field";
import FormFieldFileButton from "shared/components/form_field_file_button";
import MDButton from "shared/components/MDButton";
import { useNavigate } from "react-router-dom";
import localStorageService from "shared/services/localStorage";
import StorageTypes from "shared/services/localStorage/storageTypes";
import DefaultTab from "./templateTabs/defaults";
import SocialTab from "./templateTabs/social";
import { getImageDimensions } from "utils";

const tabs = ["Recomendados", "Social"];

function getStepContent(stepIndex, setTabValue, formData) {
  switch (stepIndex) {
    case 0:
      return <DefaultTab formData={formData} />;

    case 1:
      return <SocialTab formData={formData} />;

    default:
      return null;
  }
}

function TemplateModal({ open, onClose }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState(0);

  const [selectedImage, setSelectedImage] = useState();
  const [isUploadingDoc, setIsUploadingDoc] = useState(false);
  const [state, setState] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSelectedImage = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setFieldValue("background_image", file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        setSelectedImage(e.target.result);
        const dimensions = await getImageDimensions(e.target.result);
        setFieldValue("width", dimensions.width);
        setFieldValue("height", dimensions.height);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (values, actions) => {
    const { name, height, width, background_color, background_image } = values;
    try {
      const formD = new FormData();
      formD.append("name", name);
      formD.append("height", height);
      formD.append("width", width);
      if (background_color) formD.append("background_color", background_color);
      if (background_image) formD.append("background_image", background_image);
      setIsUploadingDoc(true);
      toastSuccess("Aguarde o termino da operação", "Aguarde");
      const res = await TemplateService.create(formD, (event) => {
        const progress = Math.round((event.loaded * 100) / event.total);
        setState(progress - 1);
      });
      if (res) setIsUploadingDoc(false);
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess(
        "Template criado com sucesso",
        "",
        true,
        "/templates",
        "Redirecionar para Templates"
      );
    } catch (e) {
      toastError(e.message);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <MDBox className={styles.modal}>
        <MDBox className={styles.modelHeader}>
          <Grid container>
            <Grid xs={11} sm={12} item>
              <MDTypography align="center">Selecionar modelo</MDTypography>
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              position="absolute"
              style={{ right: 5, top: 5 }}
            >
              <MDBox display="flex" justifyContent="flex-end">
                <IconButton size="small" onClick={onClose}>
                  <Icon fontSize="small" align="right">
                    close
                  </Icon>
                </IconButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Divider sx={{ background: theme.palette.dark.main, m: 0 }} />
        <MDBox className={styles.modelContainer}>
          <Formik
            initialValues={initialValues}
            validationSchema={validations[0]}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleBlur,
              setTouched,
              setSubmitting,
              resetForm,
            }) => {
              const {
                name: nameV,
                height: heightV,
                width: widthV,
                background_color: background_colorV,
                background_image: background_imageV,
              } = values;
              return (
                <Form
                  id="template-form"
                  autoComplete="off"
                  style={{ width: "100%" }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3} className={styles.menuAside}>
                      <MDBox mb={2}>
                        <FormField
                          name="name"
                          label="Nome do modelo *"
                          fieldVariant="outlined"
                          type="text"
                          value={nameV}
                        />
                      </MDBox>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormField
                          disabled={!!background_imageV}
                            name="width"
                            label="Largura *"
                            fieldVariant="outlined"
                            type="number"
                            value={widthV}
                            // onChange={(e) => {
                            //   const { value } = e.target;
                            //   if (value < 1) setFieldValue("width", 1);
                            //   else if (value > 9999)
                            //     setFieldValue("width", 9999);
                            //   else setFieldValue("width", Number(value));
                            // }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                          disabled={!!background_imageV}
                            name="height"
                            label="Altura *"
                            fieldVariant="outlined"
                            type="number"
                            value={heightV}
                            // onChange={(e) => {
                            //   const { value } = e.target;
                            //   if (value < 1) setFieldValue("height", 1);
                            //   else if (value > 9999)
                            //     setFieldValue("height", 9999);
                            //   else setFieldValue("height", Number(value));
                            // }}
                          />
                        </Grid>
                      </Grid>

                      <MDBox>
                        <MDTypography mb={2} variant="h5">
                          Modelo novo
                        </MDTypography>
                        <MDBox>
                          <FormField
                            fieldShrink
                            name="background_color"
                            label="Cor do modelo"
                            fieldVariant="outlined"
                            type="color"
                            value={background_colorV}
                            className={styles.formFieldSmall}
                            onChange={(event) => {
                              const { value } = event.target;
                              if (value) {
                                setFieldValue("background_image", null);
                                setFieldValue("background_color", value);
                                setSelectedImage();
                              }
                            }}
                          />
                        </MDBox>
                        <MDTypography mb={2} variant="h5">
                          ou
                        </MDTypography>
                        <FormFieldFileButton
                          name="fileModel"
                          accept=".jpg, .jpeg, .png, .webp"
                          label="Upload de imagem"
                          fullWidth
                          onChange={(event) => {
                            const { files } = event.target;
                            if (files[0]) {
                              setFieldValue("background_color", "#ffffff");
                              handleSelectedImage(event, setFieldValue);
                            }
                          }}
                        />
                      </MDBox>

                      <MDBox mb={2}>
                        <MDButton
                          color="primary"
                          size="medium"
                          fullWidth
                          onClick={() => resetForm()}
                        >
                          RESETAR CONFIGURAÇÕES
                        </MDButton>
                      </MDBox>
                      <MDBox mb={2}>
                        <MDButton
                          disabled={isSubmitting}
                          type="submit"
                          color="primary"
                          size="medium"
                          fullWidth
                        >
                          SALVAR MODELO
                        </MDButton>
                      </MDBox>
                      <MDBox mb={2}>
                        <MDButton
                          type="button"
                          color="success"
                          onClick={() => {
                            const data = {
                              name: nameV,
                              height: heightV,
                              width: widthV,
                              background_color: background_colorV,
                              background_image: background_imageV,
                              background_image_base64: selectedImage,
                              type: "modal",
                            };
                            localStorageService.set(
                              StorageTypes.BANNER_MODEL,
                              data
                            );
                            navigate("/banners/criar");
                          }}
                          size="medium"
                          fullWidth
                        >
                          Criar banner
                        </MDButton>
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                      <MDBox className={styles.modelBoxTemplateOptions}>
                        <MDBox 
                        // bgColor="primary"
                        //  borderRadius="lg"
                          mb={1}>
                          <Tabs value={tabValue} onChange={handleTabChange}>
                            {tabs.map((label) => (
                              <Tab key={label} label={label} />
                            ))}
                          </Tabs>
                        </MDBox>
                        <MDBox className={styles.modelBoxContainer}>
                          {getStepContent(tabValue, setTabValue, {
                            setFieldValue,
                          })}
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

TemplateModal.defaultProps = {
  onClose: null,
};

TemplateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default TemplateModal;
