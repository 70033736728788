import { useEffect } from "react";
import GoogleFonts from "google-fonts";

const GoogleFontsLoader = () => {
  useEffect(() => {
    GoogleFonts.add({
      "Nova Square": 400,
      // Lato: 400,
      //   "Source Sans Pro": true,
      //   Raleway: 400,
    });
  }, []);

  return null;
};

export default GoogleFontsLoader;
