/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Paper, Stack, useTheme } from "@mui/material";
import { fabric } from "fabric";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import {
  canvasEventsControl,
  canvasKeyboardEvents,
  canvasPanningOff,
  canvasZoom,
} from "./canvasFunctions";
import { dynamicMenuItemsArray } from "./menuItems/dynamicMenuItemsArray";
import styles from "./styles.module.css";
import { fixedMenuItemsArray } from "./menuItems/fixedMenuItemsArray";
import localStorageService from "shared/services/localStorage";
import StorageTypes from "shared/services/localStorage/storageTypes";
import { useNavigate } from "react-router-dom";
import templatesService from "shared/services/templates/templates.service";
import toastError from "shared/components/snackbar/error/toastError";
import LinearIndeterminate from "shared/components/loader/linearIndeterminate";

function CanvasBox({ functionState, onDrop }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { palette } = theme;

  const [canvasLoading, setCanvasLoading] = React.useState(false);

  // canvas
  const { canvas, setCanvas } = functionState;
  const [modelBanner, setModelBanner] = React.useState();
  const [activeObj, setActiveObj] = React.useState();
  const [menuType, setMenuType] = React.useState();
  const [isPanningMode, setIsPanningMode] = React.useState();
  const [zoom, setZoom] = React.useState(1);

  const [isMouseDown, setIsMouseDown] = React.useState();
  const [menuTypeClickObj, setMenuTypeClickObj] = React.useState("");

  const resetMenus = () => {
    setIsPanningMode();
    canvasPanningOff(canvas);
    // setIsMouseDown();
  };

  const initCanvas = (modelData) => {
    const {
      width: modelWidthV,
      height: modelHeightV,
      background_color: modelColorV,
      background_image_link: fileModelV,
      background_image_base64: fileModel64V,
    } = modelData;

    const heightDefault = 400;
    const containerCanvas = document.getElementById("canvas-item-box");

    const canvasRef = new fabric.Canvas("canvas", {
      width: Number(modelWidthV),
      height: Number(modelHeightV),

      backgroundColor: modelColorV,
      enableRetinaScaling: true,
      renderOnAddRemove: true,
      devicePixelRatio: window.devicePixelRatio || 1,
      selection: true,
    });

    canvasKeyboardEvents(canvasRef);
    canvasEventsControl(canvasRef);

    const startZoom = (heightDefault * zoom) / Number(modelHeightV);
    const startZoomWidth =
      (containerCanvas.clientWidth * zoom) / Number(modelWidthV);
    canvasZoom(
      canvasRef,
      Number(modelWidthV) > Number(modelHeightV) &&
        canvasRef.width > containerCanvas.clientWidth
        ? startZoomWidth * 0.8
        : startZoom,
      { zoom, setZoom }
    );

    if (fileModelV || fileModel64V) {
      fabric.Image.fromURL(
        fileModelV ?? fileModel64V,
        (img) => {
          let scaleRatio = Math.max(
            modelWidthV / img.width,
            modelHeightV / img.height
          );

          canvasRef.setBackgroundImage(
            img,
            canvasRef.renderAll.bind(canvasRef),
            {
              scaleX: scaleRatio,
              scaleY: scaleRatio,
              repeat: "no-repeat",
              backgroundStretch: true,
              left: modelWidthV / 2,
              top: modelHeightV / 2,
              originX: "middle",
              originY: "middle",
            }
          );
        },
        { crossOrigin: "Anonymous" }
      );
    }

    // Observe click in objects
    const onObjectSelected = (o) => {
      const { selected } = o;
      const { type, width, height } = selected[0];
      selected[0].scaleY += 0.1;
      canvasRef.renderAll();
      setActiveObj(selected[0]);
      setMenuType(type);
      selected[0].scaleY -= 0.1;
    };

    // Log Object Selections
    canvasRef.on("selection:created", onObjectSelected);
    canvasRef.on("selection:updated", onObjectSelected);
    canvasRef.on("selection:cleared", function () {
      setActiveObj();
      canvasRef.discardActiveObject();
    });

    setCanvas(canvasRef);
    // Clean canvas
    return () => {
      canvasRef.off("object:selected", onObjectSelected);
      canvasRef.dispose();
    };
  };

  const handleMenuObjectType = () => {
    const menuTypes = dynamicMenuItemsArray({ canvas, activeObj }, menuType);
    const hasManyObjs = canvas.getActiveObjects().length > 1;
    const mapOptions = menuTypes.filter((opt) =>
      opt.types.includes(hasManyObjs ? "multiple" : menuType)
    );
    return (
      <Stack direction="row" spacing={1} className={styles.dynamicMenuItems}>
        {mapOptions.map((value, index) => (
          <MDBox key={index}>{value.component}</MDBox>
        ))}
      </Stack>
    );
  };

  const allowDrop = (e) => e.preventDefault();

  const getTemplate = async (id) => {
    try {
      setCanvasLoading(true);
      const res = await templatesService.read(id);
      if (res) setModelBanner(res);
    } catch (e) {
      toastError(e.message);
    } finally {
      setCanvasLoading(false);
    }
  };

  const getLocalStorage = () => {
    const storage = localStorageService.get(StorageTypes.BANNER_MODEL);
    if (storage) {
      if (storage.type === "list") {
        getTemplate(storage.id);
      } else {
        setModelBanner(storage);
      }
    } else navigate(-1);
  };

  React.useEffect(() => {
    getLocalStorage();
    // return () => {
    //   setModelBanner();
    //   localStorageService.remove(StorageTypes.BANNER_MODEL);
    // };
  }, []);

  React.useEffect(() => {
    if (modelBanner) initCanvas(modelBanner);
  }, [modelBanner]);

  return (
    <>
      <Paper
        elevation={1}
        sx={{
          padding: theme.spacing(1),
        }}
        className={styles.menuFixed}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          className={styles.dynamicMenuItems}
        >
          {fixedMenuItemsArray({
            canvas,
            resetMenus,
            isPanningMode,
            setIsPanningMode,
            zoom,
            setZoom,
            isMouseDown,
            setIsMouseDown,
            menuTypeClickObj,
            setMenuTypeClickObj,
          }).map((value, index) => (
            <MDBox key={index}>{value.component}</MDBox>
          ))}
        </Stack>
      </Paper>
      <Paper
        elevation={1}
        sx={{
          padding: theme.spacing(1),
        }}
        className={styles.dynamicMenu}
      >
        {activeObj ? (
          handleMenuObjectType()
        ) : (
          <MDTypography variant="h4" align="center">
            Selecione um item abaixo
          </MDTypography>
        )}
      </Paper>

      {canvasLoading && (
        <MDBox>
          <LinearIndeterminate />
        </MDBox>
      )}
      <MDBox
        className={styles.canvasContainer}
        onDragOver={allowDrop}
        onDrop={onDrop}
        id="canvas-item-box"
      >
        <MDBox className={styles.canvasContainerItemBox}>
          <canvas id="canvas"></canvas>

          <div className={styles.canvasCloneContainer}>
            <canvas id="canvas-clone"></canvas>
          </div>
        </MDBox>
      </MDBox>
    </>
  );
}
export default CanvasBox;
