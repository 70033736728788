export const formatFileBase64 = (value) => {
  if (!value) return null;

  const png = "data:image/png;base64,";
  const jpg = "data:image/jpg;base64,";
  const jpeg = "data:image/jpeg;base64,";
  const pdf = "data:application/pdf;base64,";

  switch (value) {
    case value.includes(".png"):
      return `${png}${value}`;
    case value.includes(".jpeg"):
      return `${jpeg}${value}`;
    case value.includes(".jpg"):
      return `${jpg}${value}`;
    case value.includes(".pdf"):
      return `${pdf}${value}`;
    default:
      return `${png}${value}`;
  }
};

export function base64StringToBlob(base64String) {
  const commaIndex = base64String.indexOf(",");

  if (commaIndex === -1) {
    throw new Error(
      "Cabeçalho do tipo MIME ausente na string codificada em base64."
    );
  }
  const header = base64String.substring(0, commaIndex);
  const mimeType = header.split(":")[1].split(";")[0];
  const base64Data = base64String.substring(commaIndex + 1);
  return new Blob([Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))], {
    type: mimeType,
  });
}

export function returnOnlyNumber(value, type) {
  if (type === "number")
    return value.toString().includes("-")
      ? -Number(value.toString().replace(/\D/g, ""))
      : Number(value.toString().replace(/\D/g, ""));
  return value.toString().replace(/\D/g, "");
}

export function validationCPF(cpf) {
  let sum = 0;
  let remainder;

  const strCPF = String(cpf).replace(/[^\d]/g, "");
  if (strCPF.length !== 11) return false;

  if (
    [
      "00000000000",
      "11111111111",
      "22222222222",
      "33333333333",
      "44444444444",
      "55555555555",
      "66666666666",
      "77777777777",
      "88888888888",
      "99999999999",
    ].indexOf(strCPF) !== -1
  )
    return false;

  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(strCPF.substring(9, 10))) return false;

  sum = 0;

  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(strCPF.substring(10, 11))) return false;

  return true;
}

export const oraganizeArrayByParam = (array, param = "name") => {
  return array?.sort(function (a, b) {
    return a[param] > b[param] ? 1 : b[param] > a[param] ? -1 : 0;
  });
};

export function removeEmptyFields(obj) {
  const newObj = {};
  for (const chave in obj) {
    if (obj[chave] !== null && obj[chave] !== undefined && obj[chave] !== "") {
      newObj[chave] = obj[chave];
    }
  }
  return newObj;
}

export function isBackgroundColorDark(
  backgroundColor,
  lightColor = "#FFFFFF",
  darkColor = "#000000"
) {
  // Convert to RBG
  let r, g, b;
  if (backgroundColor.charAt(0) === "#") {
    backgroundColor = backgroundColor.substr(1);
    r = parseInt(backgroundColor.substr(0, 2), 16);
    g = parseInt(backgroundColor.substr(2, 2), 16);
    b = parseInt(backgroundColor.substr(4, 2), 16);
  } else if (backgroundColor.startsWith("rgb")) {
    const parts = backgroundColor.match(/(\d+)/g);
    r = parseInt(parts[0]);
    g = parseInt(parts[1]);
    b = parseInt(parts[2]);
  }
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  if (luminance < 128) return lightColor;
  else return darkColor;
}

export const getImageDimensions = (imageLink) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const imgWidth = this.width;
      const imgHeight = this.height;

      const imageData = {
        width: imgWidth,
        height: imgHeight,
      };

      resolve(imageData);
    };
    img.onerror = function () {
      reject("Erro ao carregar a imagem.");
    };
    img.src = imageLink;
  });
};

export const applyFonts = (name, url, format = "opentype") => {
  if (!url) return;
  const style = document.createElement("style");
  style.appendChild(
    // @import url(${url});
    document.createTextNode(`
    @font-face {
      font-family: '${name}';
      src: url('${url}') format(${format});
    }
    `)
  );
  document.head.appendChild(style);
};
