import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

export default function CircularIndeterminate(props) {
  const theme = useTheme();
  const { palette } = theme;
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress
        {...props}
        size={props.size ?? 20}
        thickness={4}
        style={{ color: props.colorel ? props.colorel : palette.light.main }}
      />
    </Box>
  );
}
