import MDTypography from "shared/components/MDTypography";

import styles from "./styles.module.css";
import MDBox from "shared/components/MDBox";

export function TooltipCalibrationFields(value) {
  switch (value) {
    case "product_key":
      return (
        <MDBox className={styles.tooltipContainer}>
          <MDTypography variant="h6">Chave do produto</MDTypography>
          <MDTypography variant="caption">
            Chave do produto é a tag em que os dados do produto estão
            armazenados, como nome, preço, descrição, etc.
          </MDTypography>
          {/* <img src={basic} className={styles.img} /> */}
        </MDBox>
      );

    case "pid":
      return (
        <MDBox className={styles.tooltipContainer}>
          <MDTypography variant="h6">Código do produto</MDTypography>
          <MDTypography variant="caption">
            Código do produto é a tag que representa o número de identificação
            do produto, muitas vezes representadas como pid, id, etc.
          </MDTypography>
          {/* <img src={basic} className={styles.img} /> */}
        </MDBox>
      );

    case "name":
      return (
        <MDBox className={styles.tooltipContainer}>
          <MDTypography variant="h6">Nome do produto</MDTypography>
          <MDTypography variant="caption">
            Nome do produto é a tag que representa o nome principal do produto.
          </MDTypography>
          {/* <img src={basic} className={styles.img} /> */}
        </MDBox>
      );

    case "category":
      return (
        <MDBox className={styles.tooltipContainer}>
          <MDTypography variant="h6">Categoria do produto</MDTypography>
          <MDTypography variant="caption">
            Categoria do produto é a tag que representa a categoria, muitas
            vezes representadas como tipo, categoria, etc.
          </MDTypography>
          {/* <img src={basic} className={styles.img} /> */}
        </MDBox>
      );

    case "description":
      return (
        <MDBox className={styles.tooltipContainer}>
          <MDTypography variant="h6">Descrição do produto</MDTypography>
          <MDTypography variant="caption">
            Descrição do produto é a tag que representa a categoria, muitas
            vezes representadas como descrição, resumo, detalhes, etc.
          </MDTypography>
          {/* <img src={basic} className={styles.img} /> */}
        </MDBox>
      );

    case "image_url":
      return (
        <MDBox className={styles.tooltipContainer}>
          <MDTypography variant="h6">Link da imagem do produto</MDTypography>
          <MDTypography variant="caption">
            Link da imagem do produto é a tag que representa o link da imagem,
            muitas vezes representadas como link da imagem, url da imagem, etc.
          </MDTypography>
          {/* <img src={basic} className={styles.img} /> */}
        </MDBox>
      );

    case "price":
      return (
        <MDBox className={styles.tooltipContainer}>
          <MDTypography variant="h6">Preço do produto</MDTypography>
          <MDTypography variant="caption">
            Preço do produto do produto é a tag que representa o preço do
            produto, muitas vezes representadas como preço, valor, etc.
          </MDTypography>
          {/* <img src={basic} className={styles.img} /> */}
        </MDBox>
      );

    case "installment_price":
      return (
        <MDBox className={styles.tooltipContainer}>
          <MDTypography variant="h6">Valor da parcela</MDTypography>
          <MDTypography variant="caption">
            Valor da parcela é a tag que representa o valor da parcela do
            produto, muitas vezes representadas como preço da parcela, valor da
            parcelaa, etc.
          </MDTypography>
          {/* <img src={basic} className={styles.img} /> */}
        </MDBox>
      );

    case "installment_month":
      return (
        <MDBox className={styles.tooltipContainer}>
          <MDTypography variant="h6">Quantidade de parcelas</MDTypography>
          <MDTypography variant="caption">
            Quantidade de parcela é a tag que representa o quantidade da parcela
            do produto, muitas vezes representadas como quantidade da parcela,
            número de parcelas, etc.
          </MDTypography>
          {/* <img src={basic} className={styles.img} /> */}
        </MDBox>
      );

    default:
      return value;
  }
}
