import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    name: Yup.string().required("O nome é obrigatório"),
    width: Yup.number()
      .required("A largura é obrigatória")
      .min(1, "O mínimo de largura é 1")
      .max(9999, "O máximo de largura é 9999"),
    height: Yup.number()
      .required("A altura é obrigatória")
      .min(1, "O mínimo de altura é 1")
      .max(9999, "O máximo de altura é 9999"),
  }),
];

export const validationsEdit = [
  Yup.object().shape({
    // first_name: Yup.string().required("O nome é obrigatório"),
    // last_name: Yup.string().required("O sobrenome é obrigatório"),
    // phone: Yup.string().required("O telefone é obrigatório"),
    // email: Yup.string()
    //   .email("Email inválido")
    //   .required("O email é obrigatório"),
  }),
];

export default validations;
