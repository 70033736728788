import * as Yup from "yup";

const validFileExtensions = ["ttf", "otf"];

function isValidFileType(fileName) {
  const extension = fileName.split(".").pop().toLowerCase();
  return validFileExtensions.includes(extension);
}

const fontAccept = [
  "abf", "afm", "bco", "bdf", "bez", "cfn", "chr", "dfont", "dwf", "e24", 
  "eot", "fli", "fon", "fot", "fnt", "gf", "inf", "L30", "mf", "mgf", "mmm", 
  "odt", "otf", "otl", "p9", "p24", "pcf", "pfa", "pfb", "pfm", "pk", "pl", 
  "ps", "psf", "pso", "pxl", "qfm", "sfl", "sfp", "sfs", "snf", "spd", 
  "suit", "tdf", "tfm", "ttc", "ttf", "vf", "vpl", "woff", "woff2",
];

const validations = Yup.object().shape({
  name: Yup.string().required("O nome é obrigatório"),
  font: Yup.mixed()
    .required("Por favor, selecione um arquivo")
    .test("fileFormat", "Formato de arquivo inválido.", (value) => {
      if (!value) return false;
      const extension = value.name.split(".").pop().toLowerCase();
      return fontAccept.includes(extension);
    }),
});

export default validations;
