import React from "react";

import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import MDBox from "../MDBox";
import HtmlTooltip from "./HtmlTooltip";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: { verticalAlign: "middle", cursor: "pointer", ml: 2 },
};

function TooltipDefault({ title, children }) {
  return (
    <MDBox sx={styles.container}>
      {children}
      <HtmlTooltip title={title}>
        <Icon color="primary" sx={styles.icon}>
          info
        </Icon>
      </HtmlTooltip>
    </MDBox>
  );
}

TooltipDefault.defaultProps = {};

TooltipDefault.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node,
};

export default TooltipDefault;
