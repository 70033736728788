import { memo, useState } from "react";
import PropTypes from "prop-types";

import { ErrorMessage, FastField } from "formik";

import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import MDInput from "shared/components/MDInput";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function FormFieldMemo({ label, name, fieldVariant, fieldShrink, ...rest }) {
  const isPassword = rest.type === "password";

  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () =>
    setIsShowPassword(!isShowPassword);

  return (
    <MDBox mb={1.5} sx={{ width: "100%" }}>
      <MDBox style={{ position: "relative" }}>
        {isPassword ? (
          <IconButton
            onClick={handleTogglePasswordVisibility}
            style={{
              position: "absolute",
              margin: "3px 0",
              right: "8%",
              zIndex: 5,
            }}
          >
            {isShowPassword ? (
              <Visibility color="info" />
            ) : (
              <VisibilityOff color="info" />
            )}
          </IconButton>
        ) : null}
        <FastField
          {...rest}
          type={isPassword && isShowPassword ? "text" : rest.type}
          name={name}
          as={MDInput}
          label={label}
          fullWidth
          variant={fieldVariant}
          InputLabelProps={fieldShrink ? { shrink: fieldShrink } : null}
        />
      </MDBox>
      <MDBox>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          mt={0.75}
        >
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

FormFieldMemo.defaultProps = {
  label: "",
  fieldVariant: "standard",
  fieldShrink: false,
};

FormFieldMemo.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  fieldVariant: PropTypes.string,
  fieldShrink: PropTypes.bool,
};

export default memo(FormFieldMemo);
