/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";
import { useState, useEffect } from "react";
import MDButton from "shared/components/MDButton";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import FormField from "shared/components/form_field";
import initialValues from "../schemas/initial_values";
import { validationsEdit } from "../schemas/validations";
import AdminsService from "shared/services/admins/admins.service";
import { maskPhone } from "utils/masks";
import toastError from "shared/components/snackbar/error/toastError";
import { formFieldDDIAdornment } from "shared/components/form_field/form_field_configs";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import { errorMultipleMsgToast } from "utils/errorStatusCode";

function AdminsEdit() {
  const { uuid } = useParams();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  const loadAdmin = async () => {
    try {
      const admin = await AdminsService.read(uuid);
      setInitialValuesFromApi({
        ...admin.user,
        id: admin.user.id || "",
        first_name: admin.user.first_name || "",
        last_name: admin.user.last_name || "",
        phone: maskPhone(admin.user.phone) || "",
        email: admin.user.email || "",
      });
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      const res = await AdminsService.edit({
        ...values,
        uuid,
      });
      if (
        res.email !== initialValuesFromApi.email &&
        res.hasOwnProperty("token")
      )
        localStorage.setItem("TOKEN", res.token);
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess(
        "Administrador editado com sucesso",
        "",
        true,
        "/admins",
        "Redirecionar para Administradores"
      );
    } catch (e) {
      errorMultipleMsgToast(e);
    }
  };

  useEffect(() => {
    loadAdmin();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValuesFromApi ?? initialValues}
        validationSchema={validationsEdit[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const {
            first_name: first_nameV,
            last_name: last_nameV,
            phone: phoneV,
            email: emailV,
            // password: passwordV,
          } = values;

          return (
            <Form id="user-edit-form" autoComplete="off">
              <Card id="basic-info" sx={{ overflow: "visible" }}>
                <MDBox p={3}>
                  <MDTypography variant="h5">Editar Administrador</MDTypography>
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="first_name"
                    label="Nome *"
                    type="text"
                    value={first_nameV}
                    error={errors.first_name && touched.first_name}
                    success={first_nameV.length > 0 && !errors.first_name}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="last_name"
                    label="Sobrenome *"
                    type="text"
                    value={last_nameV}
                    error={errors.last_name && touched.last_name}
                    success={last_nameV.length > 0 && !errors.last_name}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="phone"
                    label="Telefone *"
                    type="text"
                    value={phoneV}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("phone", maskPhone(value));
                    }}
                    InputProps={{
                      startAdornment: formFieldDDIAdornment(),
                    }}
                    error={errors.phone && touched.phone}
                    success={phoneV.length > 0 && !errors.phone}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="email"
                    label="Email *"
                    type="email"
                    value={emailV}
                    error={errors.email && touched.email}
                    success={emailV.length > 0 && !errors.email}
                  />
                </MDBox>

                {/* <MDBox px={3}>
                  <FormField
                    name="password"
                    label="Senha *"
                    type="password"
                    value={passwordV}
                  />
                </MDBox> */}

                <MDBox py={3} px={3} width="12rem" ml="auto">
                  <MDButton
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                    size="large"
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default AdminsEdit;
