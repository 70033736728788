import * as Yup from "yup";

const validFileExtensions = { image: ["ttf", "otf"] };

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

const validations = [
  Yup.object().shape({
    name: Yup.string().required("O nome é obrigatório"),
    // file: Yup.mixed()
    //   .required("Required")
    //   .test("is-valid-type", "Not a valid image type", (value) =>
    //     isValidFileType(value && value.name.toLowerCase(), "image")
    //   ),
  }),
];

export default validations;
