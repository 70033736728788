import { isAxiosError } from "axios";
import { TEMPLATE_API } from "./templates";
import customAxios from "../axiosCustom";
import errorStatusCode from "utils/errorStatusCode";
import ReactDOMServer from "react-dom/server";

const errorMsgHttp = (data, defaultMsg) => {
  if (typeof data === "string") {
    return data;
  } else {
    const element = (
      <div>
        {data.map((msg, i) => (
          <p key={i}>{msg}</p>
        ))}
      </div>
    );
    const jsxString = ReactDOMServer.renderToString(element);
    return jsxString;
  }
};

class TemplateService {
  create = async (body, loadFunction = () => {}) => {
    try {
      const response = await customAxios.post(TEMPLATE_API, body, {
        onUploadProgress: (event) => loadFunction(event),
      });

      if (!response) new Error("Não foi possível registrar Modelo");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível criar Modelo";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data?.message);

          case 404:
            throw new Error(error.response?.data?.message);

          case 409:
            throw new Error(error.response?.data?.message);

          case 422:
            throw new Error(
              errorMsgHttp(error.response?.data?.message, defaultMsg)
            );

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  edit = async (body, uuid, loadFunction = () => {}) => {
    try {
      const response = await customAxios.post(`${TEMPLATE_API}/${uuid}`, body, {
        onUploadProgress: (event) => loadFunction(event),
      });

      if (!response) new Error("Não foi possível editar Modelo");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível editar Modelo";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data?.message);

          case 404:
            throw new Error(error.response?.data?.message);

          case 409:
            throw new Error(error.response?.data?.message);

          case 422:
            throw new Error(error.response?.data);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  list = async (query) => {
    try {
      const response = await customAxios.get(`${TEMPLATE_API}${query}`);

      if (!response) new Error("Não foi possível carregar Modelo");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error("Modelo não registrado");

          default:
            throw new Error("Não foi possível carregar Modelo");
        }
      }

      throw new Error(error);
    }
  };

  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${TEMPLATE_API}/${uuid}`);

      if (!response) new Error("Não foi possível carregar Modelo");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error("Modelo não registrado");

          default:
            throw new Error("Não foi possível carregar Modelo");
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${TEMPLATE_API}/${uuid}`);

      if (!response) new Error("Não foi possível apagar Modelo");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível apagar Modelo";

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 422:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };
}

export default new TemplateService();
