import { isAxiosError } from "axios";
import { ADMIN_API, ADMINS_API } from "./admins";
import customAxios from "../axiosCustom";
import errorStatusCode from "utils/errorStatusCode";
import ReactDOMServer from "react-dom/server";

const errorMsgHttp = (data, defaultMsg) => {
  if (typeof data === "string") {
    return data;
  } else {
    const element = (
      <div>
        {data.map((msg, i) => (
          <p key={i}>{msg}</p>
        ))}
      </div>
    );
    const jsxString = ReactDOMServer.renderToString(element);
    return jsxString;
  }
};

class AdminsService {
  create = async ({ first_name, last_name, phone, email }) => {
    try {
      const response = await customAxios.post(ADMIN_API, {
        first_name,
        last_name,
        phone,
        email,
      });

      if (!response) new Error("Não foi possível criar administrador");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível criar administrador";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data?.message);

          case 404:
            throw new Error(error.response?.data?.message);

          case 409:
            throw new Error(error.response?.data?.message);

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${ADMIN_API}/${uuid}`);

      if (!response) new Error("Não foi possível carregar administrador");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error("Não foi possível carregar administrador");
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = "") => {
    try {
      const response = await customAxios.get(`${ADMINS_API}${filter}`);

      if (!response) new Error("Não foi possível listar administradores");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error("Não foi possível listar administradores");
        }
      }

      throw new Error(error);
    }
  };

  edit = async ({ uuid, first_name, last_name, phone, email }) => {
    try {
      const response = await customAxios.patch(`${ADMIN_API}/${uuid}`, {
        first_name,
        last_name,
        phone,
        email,
      });

      if (!response) new Error("Não foi possível editar administrador");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível editar administrador";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data?.message);

          case 404:
            throw new Error(error.response?.data?.message);

          case 409:
            throw new Error(error.response?.data?.message);

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  updateStatus = async ({ id, status }) => {
    try {
      const response = await customAxios.patch(`${ADMIN_API}/${id}`, {
        status,
      });

      if (!response) new Error("Não foi possível editar administrador");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const defaultMsg = "Não foi possível editar administrador";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${ADMIN_API}/${uuid}`);

      if (!response) new Error("Não foi possível apagar administrador");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível apagar administrador";

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 422:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };
}

export default new AdminsService();
