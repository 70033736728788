import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    first_name: Yup.string().required("O nome é obrigatório"),
    last_name: Yup.string().required("O sobrenome é obrigatório"),
    phone: Yup.string().required("O telefone é obrigatório"),
    email: Yup.string()
      .email("Email inválido")
      .required("O email é obrigatório"),
    document: Yup.string().required("O CPF/CNPJ é obrigatório"),
    company_name: Yup.string().required("O nome da empresa é obrigatório"),
    postal_code: Yup.string().required("O CEP é obrigatório"),
    street: Yup.string().required("O logradouro é obrigatório"),
    neighborhood: Yup.string().required("O bairro é obrigatório"),
    city: Yup.string().required("A cidade é obrigatória"),
    state: Yup.string().required("O estado é obrigatório"),
    // complement: Yup.string().required("O complemento é obrigatório"),
    // banner_credits: Yup.number()
    //   .required("O crédito de banner é obrigatorio")
    //   .min(0, "O número deve ser no mínimo 0."),
  }),
];

export const validationsEdit = [
  Yup.object().shape({
    first_name: Yup.string().required("O nome é obrigatório"),
    last_name: Yup.string().required("O sobrenome é obrigatório"),
    phone: Yup.string().required("O telefone é obrigatório"),
    email: Yup.string()
      .email("Email inválido")
      .required("O email é obrigatório"),
    document: Yup.string().required("O CPF/CNPJ é obrigatório"),
    company_name: Yup.string().required("O nome da empresa é obrigatório"),
    postal_code: Yup.string().required("O CEP é obrigatório"),
    street: Yup.string().required("O logradouro é obrigatório"),
    neighborhood: Yup.string().required("O bairro é obrigatório"),
    city: Yup.string().required("A cidade é obrigatória"),
    state: Yup.string().required("O estado é obrigatório"),
    // complement: Yup.string().required("O complemento é obrigatório"),
    // banner_credits: Yup.number()
    //   .required("O crédito de banner é obrigatorio")
    //   .min(0, "O valor deve ser no mínimo 0."),
  }),
];

export default validations;
