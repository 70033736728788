/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Icon } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";

import "./styles.css";
import { useState } from "react";

import smartphone from "assets/images/template-icons/smartphone.svg";
import browser from "assets/images/template-icons/browser.svg";
import play from "assets/images/template-icons/play.svg";

const modelList = [
  {
    name: "Post de Mídia Social",
    icon: smartphone,
    width: "1080",
    height: "1080",
  },
  {
    name: "Story de Mídia Social",
    icon: smartphone,
    width: "1080",
    height: "1920",
  },
  {
    name: "Web",
    icon: browser,
    width: "1600",
    height: "900",
  },
  {
    name: "Thumb 720p",
    icon: play,
    width: "1280",
    height: "720",
  },
  {
    name: "Wide 1080p",
    icon: play,
    width: "1920",
    height: "1080",
  },
];

function DefaultTab({ formData }) {
  const [focusedElement, setfocusedElement] = useState(null);
  const { setFieldValue } = formData;

  const handleSetFormValues = (data) => {
    const { height, width } = data;
    setFieldValue("width", width);
    setFieldValue("height", height);
  };

  function focusElement(index) {
    setfocusedElement(index);
  }

  return (
    <Grid container spacing={1} maxHeight={400}>
      {modelList.map((v, i) => {
        return (
          <Grid item key={i}>
            <MDBox
              className={`modelListItem ${
                focusedElement === i ? "modelListItemFocus" : ""
              }`}
              onClick={(e) => {
                handleSetFormValues(v);
                focusElement(i);
              }}
            >
              <MDBox className={"modelListItemImg"}>
                {v.icon && <img src={v.icon} alt="Minha Imagem SVG" />}
                {/* <Icon fontSize="large">public</Icon> */}
              </MDBox>
              <MDTypography
                className={"modelListItemText"}
                variant="h6"
                align="center"
                sx={{ fontSize: "14px" }}
              >
                {v.name}
              </MDTypography>
              <MDTypography
                className={"modelListItemText"}
                variant="body2"
                align="center"
                sx={{ fontSize: "12px" }}
              >
                {v.width} x {v.height}
              </MDTypography>
            </MDBox>
          </Grid>
        );
      })}
    </Grid>
  );
}
export default DefaultTab;
