import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    document: Yup.string()
      .email("e-mail inválido")
      .required("O e-mail é obrigatório"),
    password: Yup.string().required("A senha é obrigatória"),
  }),
];

export default validations;
