const localStorageService = {
  get: function (key) {
    try {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    } catch (error) {
      console.error("Erro ao obter item do LocalStorage:", error);
      return null;
    }
  },

  set: function (key, data) {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      console.error("Erro ao definir item no LocalStorage:", error);
    }
  },

  remove: function (key) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error("Erro ao remover item do LocalStorage:", error);
    }
  },

  clear: function () {
    try {
      localStorage.clear();
    } catch (error) {
      console.error("Erro ao limpar o LocalStorage:", error);
    }
  },
};

export default localStorageService;
