/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import { Card, Tab, Tabs } from "@mui/material";

import MDBox from "shared/components/MDBox";

import styles from "./styles.module.css";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";

import CalibrationXML from "./tabs/calibration";
import FontList from "./tabs/font_page";

const tabs = ["Calibrar XML", "Fontes"];

function getStepContent(stepIndex, setTabValue) {
  switch (stepIndex) {
    case 0:
      return <CalibrationXML />;

    case 1:
      return <FontList />;

    default:
      return null;
  }
}

function CanvasSettingsPage() {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox className={styles.modelContainer}>
          <MDBox className={styles.modelBoxTemplateOptions}>
            <MDBox mb={1}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                {tabs.map((label) => (
                  <Tab key={label} label={label} />
                ))}
              </Tabs>
            </MDBox>
            <MDBox className={styles.modelBoxContainer}>
              {getStepContent(tabValue, setTabValue)}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default CanvasSettingsPage;
