/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Card, Grid, Icon, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";
import DataTableApi from "shared/components/tables/DatatableApi";

import MDInput from "shared/components/MDInput";
import { useEffect, useState } from "react";
import MDButton from "shared/components/MDButton";
import NotificationItem from "shared/examples/Items/NotificationItem";
import PopUp, { dialogButton } from "shared/components/pop_up";
import { useNavigate } from "react-router-dom";
import MDProgress from "shared/components/MDProgress";
import toastError from "shared/components/snackbar/error/toastError";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import mockBannerList from "./mocks/mockList";
import bannersService from "shared/services/banners/banners.service";
import StorageTypes from "shared/services/localStorage/storageTypes";
import localStorageService from "shared/services/localStorage";
import TemplateModal from "./components/templateModal";
import { userLogged } from "utils/essentialData";

function Banners() {
  const navigate = useNavigate();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [bannerData, setBannerData] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [filter, setFilter] = useState("");
  const [filterSearch, setFilterSearch] = useState("");

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  // template modal
  const [openModal, setOpenModal] = useState(false);

  const [isNoCredits, setIsNoCredits] = useState(false);

  const handleDelete = (row) => {
    return (
      <IconButton
        onClick={() => {
          const data = row.original;
          setOpenPopUp(true);
          setBannerData(data);
        }}
      >
        <Icon>delete</Icon>
      </IconButton>
    );
  };

  const handleEdit = (row) => {
    return (
      <IconButton
        onClick={() => {
          const { id } = row.original;
          navigate(`/banners/editar/${id}`);
        }}
      >
        <Icon>visibility</Icon>
      </IconButton>
    );
  };

  const dataTableData = {
    columns: [
      {
        Header: "nome",
        accessor: "name",
      },
      {
        Header: "Visualizar",
        accessor: "view",
        Cell: ({ cell: { row } }) => handleEdit(row),
        width: "20%",
      },
      {
        Header: "Deletar",
        accessor: "deleteT",
        Cell: ({ cell: { row } }) => handleDelete(row),
        width: "20%",
      },
      // {
      //   Header: "ações",
      //   accessor: "action",
      //   width: "15%",
      // },
    ],

    rows: dataTable || [],
  };

  const handleCancelDelete = () => setOpenPopUp(false);

  const handleApplyDelete = async () => {
    try {
      const { id, name } = bannerData;
      await bannersService.delete(id);
      toastSuccess("Banner deletado com sucesso");
      loadBanners(tablePage, tableLimit, filterSearch);
    } catch (e) {
      if (e.message.includes("<p>")) {
        toastError(<div dangerouslySetInnerHTML={{ __html: e.message }} />);
      } else toastError(e.message);
    } finally {
      setOpenPopUp(false);
    }
  };

  const loadBanners = async (page = 1, limit = 5, filter = "") => {
    try {
      setLoadingData(true);
      const filterValue = filter !== "" ? `&filter=${filter.trim()}` : "";
      const res = await bannersService.list(
        `?page=${page}&limit=${limit}${filterValue}`
      );

      if (res) {
        setDataTable(res.data);
        setTableTotal(res.meta.totalCount);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    if (value === "") {
      loadBanners(1, tableLimit);
      setTablePage(1);
      setFilterSearch("");
    }
    setFilter(value);
  };

  const handleClickSearch = () => {
    setFilterSearch(filter.trim());
    setTablePage(1);
    loadBanners(1, tableLimit, filter);
  };

  useEffect(() => {
    loadBanners(tablePage, tableLimit, filterSearch);
    return () => {
      setDataTable();
      setTableTotal(0);
    };
  }, [tablePage, tableLimit]);

  useEffect(() => {
    const noCredits = userLogged().banner_credits === 0;
    if (noCredits) setIsNoCredits(true);
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={3}>
          <Card>
            <MDBox px={3} pt={3}>
              <MDBox mb={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Banners
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDBox width="12rem" ml="auto">
                      <MDButton
                        disabled={isNoCredits}
                        variant="gradient"
                        color="primary"
                        size="small"
                        fullWidth
                        onClick={() => {
                          setOpenModal(true);
                        }}
                      >
                        Novo banner
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {/* <MDBox mt={2}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <MDInput
                      type="text"
                      name="filter"
                      value={filter}
                      size="small"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            sx={{ visibility: filter ? "visible" : "hidden" }}
                            size="small"
                            onClick={() =>
                              handleChange({ target: { value: "" } })
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={2} display="flex">
                    <MDButton
                      disabled={!filter}
                      variant="gradient"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={handleClickSearch}
                    >
                      BUSCAR
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox> */}
            </MDBox>
            <MDBox mt={2}>
              {loadingData ? (
                <MDProgress
                  variant="gradient"
                  variantProgress="indeterminate"
                  color="primary"
                />
              ) : (
                <DataTableApi
                  table={dataTableData}
                  onRowSelected={setRowSelected}
                  entriesPerPage={{ defaultValue: tableLimit }}
                  setTableLimit={setTableLimit}
                  tableLimit={tableLimit}
                  setTablePage={setTablePage}
                  tablePage={tablePage}
                  tableTotal={tableTotal}
                >
                  {[
                    <NotificationItem
                      onClick={() => {
                        const { id } = rowSelected.original;
                        navigate("/banners/editar/" + id);
                      }}
                      icon={<Icon>create</Icon>}
                      title="Editar"
                      key="option_edit"
                    />,
                    // <NotificationItem
                    //   onClick={() => {
                    //     const { status } = rowSelected.original;
                    //     setOpenPopUp(true);
                    //     setUserStatus(status);
                    //   }}
                    //   icon={<Icon>create</Icon>}
                    //   title="Alterar status"
                    //   key="option_delete"
                    // />,
                  ]}
                </DataTableApi>
              )}
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
      <TemplateModal open={openModal} onClose={() => setOpenModal(false)} />
      <PopUp
        open={openPopUp}
        onClose={() => setRowSelected()}
        title="Deletar banner"
        contentText={`Deseja deletar permanentemente o banner?`}
        actions={[
          <MDButton
            color="dark"
            onClick={handleCancelDelete}
            style={dialogButton}
            key="cancel"
          >
            cancelar
          </MDButton>,
          <MDButton
            color="error"
            onClick={handleApplyDelete}
            style={dialogButton}
            key="confirm"
          >
            confirmar
          </MDButton>,
        ]}
      />
    </>
  );
}

export default Banners;
