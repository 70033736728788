import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    // link: Yup.string().required("O link do XML é obrigatório"),
    // xmlFile:Yup.file().required("O link do XML é obrigatório"),
  }),
];

export const validationsCalibration = [
  Yup.object().shape({
    product_key: Yup.string().required("Este campo é obrigatório"),
    pid: Yup.string().required("Este campo é obrigatório"),
  }),
];

export default validations;
