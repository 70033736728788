import ReactDOMServer from "react-dom/server";
import toastError from "shared/components/snackbar/error/toastError";

const errorStatusCode = (statusCode) => {
  switch (statusCode) {
    case 400: // Bad Request
      return "Não foi possível continuar com a operação.";

    case 401: // Unauthorized
      return "Informações incorretas ou sessão inválida.";

    case 403: // Forbidden
      return "Usuário sem permissão suficiente.";

    case 409: // Conflict
      return "Erro interno do servidor";

    case 429: // Too Many Requests
      return "Erro interno do servidor.";

    case 500: // Internal Server Error
      return "Erro interno do servidor.";

    case undefined: // Internal Server Error || err_connection_timed_out
      return "Erro interno do servidor.";

    default:
      return "Operação desconhecida!";
  }
};

export default errorStatusCode;

export const errorMultipleMsgHttp = (data, defaultMsg) => {
  if (typeof data === "string") {
    return data;
  } else {
    const element = (
      <div>
        {data.map((msg, i) => (
          <p key={i}>{msg}</p>
        ))}
      </div>
    );
    const jsxString = ReactDOMServer.renderToString(element);
    return jsxString;
  }
};

export const errorMultipleMsgToast = (e) => {
  if (e.message.includes("<p>")) {
    toastError(<div dangerouslySetInnerHTML={{ __html: e.message }} />);
  } else toastError(e.message);
}
