/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import FormField from "shared/components/form_field";
import { initialValuesCalibrations } from "../../schemas/initial_values";
import { Grid, Paper, Stack, useTheme } from "@mui/material";
import MDTypography from "shared/components/MDTypography";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import calibrationService from "shared/services/calibration/calibration.service";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import toastError from "shared/components/snackbar/error/toastError";
import { validationsCalibration } from "../../schemas/validations";
import { xmlCalibrationKeys } from "utils/essentialData";
import { translateXMLCalibrationKeys } from "utils/essentialData";
import TooltipDefault from "shared/components/tooltip";
import { TooltipCalibrationFields } from "../tooltipCalibrationFields";

const CalibrationForm = ({data}) => {
  const theme = useTheme();
  console.log("data final => ", data)
  const [calibrationFromApi, setCalibrationFromApi] = useState();
  const [isMounted, setIsMounted] = useState(true);

  const calibrationSubmit = async (values, actions) => {
    try {
      const data = {
        product_key: values.product_key || "product_key",
        pid: values.pid || "pid",
        name: values.name || "name",
        category: values.category || "category",
        description: values.description || "description",
        image_url: values.image_url || "image_url",
        price: values.price || "price",
        installment_price: values.installment_price || "installment_price",
        installment_month: values.installment_month || "installment_month",
      };
      await calibrationService.createCalibration(data);

      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess("Calibragem registrada com sucesso");
    } catch (e) {
      toastError(e.message);
    }
  };

  const loadCalibration = async () => {
    try {
      const data = await calibrationService.getCalibration();
      if (data && isMounted) {
          setCalibrationFromApi(data);
      }
    } catch (e) {
      if (e.message !== "Calibragem não registrada") toastError(e.message);
      else toastError(e.message);
    }
  };

  const requiredField = (field) => {
    let asterisk = "";
    if (field === "product_key") asterisk = "*";
    if (field === "pid") asterisk = "*";
    return (
      <div>
        {translateXMLCalibrationKeys(field)}{" "}
        <strong style={{ color: "red" }}>{asterisk}</strong>
      </div>
    );
  };

  useEffect(() => {
    loadCalibration();
    return () => {
      setCalibrationFromApi();
      setIsMounted(false);
    };
  }, []);

  return (
    <Formik
      initialValues={{
        ...initialValuesCalibrations,
        ...(data || {}) // Adiciona os valores de data se existirem
      }}
      validationSchema={validationsCalibration[0]}
      onSubmit={calibrationSubmit}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <MDBox display="flex" flexDirection="column" my={2}>
            <MDBox mb={2}>
              <MDTypography variant="h2" align="center">
                Calibragem do XML
              </MDTypography>
            </MDBox>
            <MDBox
              mb={2}
              p={2}
              style={{ background: theme.palette.light.main }}
            >
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} sm={5}>
                  <MDTypography variant="h5" align="center" mb={2}>
                    Sistema
                  </MDTypography>
                  <Stack spacing={1.6}>
                    {xmlCalibrationKeys.map((v, i) => (
                      <Paper
                        style={{ padding: theme.spacing(1.1) }}
                        align="center"
                        key={i}
                      >
                        <TooltipDefault title={TooltipCalibrationFields(v)}>
                          <MDTypography sx={{ fontSize: "1rem" }}>
                            {translateXMLCalibrationKeys(v)}
                          </MDTypography>
                        </TooltipDefault>
                      </Paper>
                    ))}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <MDTypography variant="h5" align="center" mb={2}>
                    XML
                  </MDTypography>
                  <Stack spacing={0}>
                    <MDBox>
                      {xmlCalibrationKeys.map((key, index) => (
                        <FormField
                          key={index}
                          fieldVariant="outlined"
                          style={{
                            background: "white",
                            marginBottom: "1px !important",
                          }}
                          name={`${key}`}
                          label={requiredField(key)}
                          type="text"
                        />
                      ))}
                    </MDBox>
                  </Stack>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox display="flex" gap="5px" justifyContent="center">
              <MDButton color="primary" type="submit" size="large">
                Calibrar
              </MDButton>
            </MDBox>
          </MDBox>
        </Form>
      )}
    </Formik>
  );
};

export default CalibrationForm;
