import { isAxiosError } from "axios";
import { PROFESSIONAL_API, PROFESSIONALS_API } from "./professionals";
import customAxios from "../axiosCustom";
import errorStatusCode from "utils/errorStatusCode";
import { errorMultipleMsgHttp } from "utils/errorStatusCode";

class ProfessionalsService {
  create = async (body) => {
    try {
      const response = await customAxios.post(PROFESSIONAL_API, body);

      if (!response) new Error("Não foi possível criar usuário");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível criar usuário";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data?.message);

          case 404:
            throw new Error(error.response?.data?.message);

          case 409:
            throw new Error(error.response?.data?.message);

          case 422:
            throw new Error(
              errorMultipleMsgHttp(error.response?.data?.message)
            );

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${PROFESSIONAL_API}/${uuid}`);

      if (!response) new Error("Não foi possível carregar usuário");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error("Não foi possível carregar usuário");
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = "") => {
    try {
      const response = await customAxios.get(`${PROFESSIONALS_API}${filter}`);

      if (!response) new Error("Não foi possível listar profissionais");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error("Não foi possível listar profissionais");
        }
      }

      throw new Error(error);
    }
  };

  edit = async (body, uuid) => {
    try {
      const response = await customAxios.patch(
        `${PROFESSIONAL_API}/${uuid}`,
        body
      );

      if (!response) new Error("Não foi possível editar usuário");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível editar usuário";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data?.message);

          case 404:
            throw new Error(error.response?.data?.message);

          case 409:
            throw new Error(error.response?.data?.message);

          case 422:
            throw new Error(
              errorMultipleMsgHttp(error.response?.data?.message)
            );

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  updateStatus = async ({ id, status }) => {
    try {
      const response = await customAxios.patch(`${PROFESSIONAL_API}/${id}`, {
        status,
      });

      if (!response) new Error("Não foi possível editar usuário");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const defaultMsg = "Não foi possível editar usuário";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data?.message);

          case 422:
            throw new Error(
              errorMultipleMsgHttp(error.response?.data, defaultMsg)
            );

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${PROFESSIONAL_API}/${uuid}`);

      if (!response) new Error("Não foi possível apagar usuário");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível apagar usuário";

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 422:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };
}

export default new ProfessionalsService();
