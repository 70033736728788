import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "fabric-history";

import "./fonts.css";
// Materia l Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import { AuthProvider } from "context/AuthContext";

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
