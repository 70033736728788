/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDTypography from "shared/components/MDTypography";

// Custom styles for MDProgress
import MDProgressRoot from "shared/components/MDProgress/MDProgressRoot";
import MDBox from "../MDBox";

const MDProgress = forwardRef(
  ({ variant, color, value, label, variantProgress, ...rest }, ref) => (
    <MDBox sx={{ overflow: "hidden" }}>
      {label && (
        <MDTypography variant="button" fontWeight="medium" color="text">
          {value}%
        </MDTypography>
      )}

      <MDProgressRoot
        {...rest}
        ref={ref}
        variant={variantProgress}
        value={value}
        ownerState={{ color, value, variant, variantProgress }}
      />
    </MDBox>
  )
);

// Setting default values for the props of MDProgress
MDProgress.defaultProps = {
  variant: "contained",
  variantProgress: "determinate",
  color: "primary",
  value: 0,
  label: false,
};

// Typechecking props for the MDProgress
MDProgress.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  variantProgress: PropTypes.oneOf(["determinate", "indeterminate"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  value: PropTypes.number,
  label: PropTypes.bool,
};

export default MDProgress;
