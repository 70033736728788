import { isAxiosError } from "axios";
import { FONT_API } from "./fonts";
import customAxios from "../axiosCustom";
import errorStatusCode from "utils/errorStatusCode";
import ReactDOMServer from "react-dom/server";
import { applyFonts } from "utils";
import StorageTypes from "shared/services/localStorage/storageTypes";
import localStorageService from "shared/services/localStorage";

const errorMsgHttp = (data, defaultMsg) => {
  if (typeof data === "string") {
    return data;
  } else {
    const element = (
      <div>
        {data.map((msg, i) => (
          <p key={i}>{msg}</p>
        ))}
      </div>
    );
    const jsxString = ReactDOMServer.renderToString(element);
    return jsxString;
  }
};

class FontsService {
  create = async (body, loadFunction = () => {}) => {
    try {
      const response = await customAxios.post(FONT_API, body, {
        onUploadProgress: (event) => loadFunction(event),
      });

      if (!response) new Error("Não foi possível criar fonte");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível criar fonte";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data?.message);

          case 404:
            throw new Error(error.response?.data?.message);

          case 409:
            throw new Error(error.response?.data?.message);

          case 415:
            throw new Error(error.response?.data?.message);

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${FONT_API}/${uuid}`);

      if (!response) new Error("Não foi possível carregar fonte");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error("Não foi possível carregar fonte");
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = "") => {
    try {
      const response = await customAxios.get(`${FONT_API}${filter}`);

      if (!response) new Error("Não foi possível listar fontes");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error("Não foi possível listar fontes");
        }
      }

      throw new Error(error);
    }
  };

  edit = async (body, uuid) => {
    try {
      const response = await customAxios.patch(`${FONT_API}/${uuid}`, body);

      if (!response) new Error("Não foi possível editar fonte");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível editar fonte";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error(error.response?.data?.message);

          case 404:
            throw new Error(error.response?.data?.message);

          case 409:
            throw new Error(error.response?.data?.message);

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  updateStatus = async ({ id, status }) => {
    try {
      const response = await customAxios.patch(`${FONT_API}/${id}`, {
        status,
      });

      if (!response) new Error("Não foi possível editar fonte");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const defaultMsg = "Não foi possível editar fonte";
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${FONT_API}/${uuid}`);

      if (!response) new Error("Não foi possível apagar fonte");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = "Não foi possível apagar fonte";

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 422:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  // --------------------- utils ---------------------------------------
  listApplyFont = async (filter = "") => {
    try {
      const response = await customAxios.get(`${FONT_API}${filter}`);

      if (!response) new Error("Não foi possível listar fontes");

      const data = response?.data;

      if (data) {
        data?.data.forEach(({ name, file_link, format }) => {
          applyFonts(name, file_link, format);
        });

        localStorageService.set(
          StorageTypes.FONT_OPTIONS,
          data.data.map((v) => v.name)
        );
      }
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error?.response?.status;
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data?.message);

          default:
            throw new Error("Não foi possível listar fontes");
        }
      }

      throw new Error(error);
    }
  };
}

export default new FontsService();
