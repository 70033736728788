import axios from "axios";
import URL_API from "../api_url";
import { redirectPage } from "utils/redirectPage";

const customAxios = axios.create({
  baseURL: URL_API,
});

const requestHandler = (request) => {
  request.headers.Authorization = `Bearer ${localStorage.getItem("TOKEN")}`;
  return request;
};

const responseHandler = (response) => response;

const errorHandler = (error) => Promise.reject(error);

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => {
    if (error.response.status === 401) {
      redirectPage("/entrar");
      localStorage.clear();
    }
    return errorHandler(error);
  }
);

export default customAxios;
