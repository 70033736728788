import PropTypes from "prop-types";

import { ErrorMessage } from "formik";

import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import MDButton from "../MDButton";
import { styled } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  whiteSpace: "nowrap",
  cursor: "pointer",
  display: "none",
});

function FormFieldFileButton({
  label,
  name,
  accept,
  fullWidth,
  size,
  ...rest
}) {
  return (
    <MDBox mb={1.5}>
      <MDBox style={{ position: "relative" }}>
        <VisuallyHiddenInput {...rest} type="file" accept={accept} id="file" />

        <label htmlFor="file">
          <MDButton
            variant="contained"
            color="primary"
            fullWidth={fullWidth}
            component="span"
            size={size}
          >
            {label}
          </MDButton>
        </label>
      </MDBox>
      <MDBox>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          mt={0.75}
        >
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

FormFieldFileButton.defaultProps = {
  label: "Upload File",
  accept: "",
  fullWidth: false,
  size: "small",
};

FormFieldFileButton.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
};

export default FormFieldFileButton;
