/* eslint-disable no-unused-vars */
import { Card, Grid } from "@mui/material";
import MDBox from "shared/components/MDBox";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";
import { useState } from "react";
import { Form, Formik } from "formik";
import initialValues from "../schemas/initial_values";
import validations from "../schemas/validations";
import bannersService from "shared/services/banners/banners.service";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import toastError from "shared/components/snackbar/error/toastError";
import { htmlToFile } from "shared/services/html2Canvas";
import localStorageService from "shared/services/localStorage";
import StorageTypes from "shared/services/localStorage/storageTypes";
import BannerMenuAside from "./components/menuAside";
import CanvasContainer from "./components/canvasContainer";
import AuthService from "shared/services/auth/auth.service";

function BannersCreate() {
  const [activeStep, setActiveStep] = useState(0);
  const currentValidation = validations[activeStep];

  // canvas
  const [canvas, setCanvas] = useState("");

  // submit
  const [isUploadingDoc, setIsUploadingDoc] = useState(false);
  const [state, setState] = useState(0);

  const [bannerCreated, setBannerCreated] = useState(false);

  // remove background
  const [isRemoveBackground, setIsRemoveBackground] = useState(false);
  const [removeBackgroundLoading, setRemoveBackgroundLoading] = useState(false);

  const submitForm = async (values, actions) => {
    const { bannerName } = values;
    const storage = localStorageService.get(StorageTypes.BANNER_MODEL);
    const { width, height } = storage;
    try {
      canvas.discardActiveObject().renderAll();
      const blob = await htmlToFile(canvas);
      const formD = new FormData();
      formD.append("banner", blob, `${bannerName}.png`);
      formD.append("name", bannerName);

      setIsUploadingDoc(true);
      toastSuccess("Aguarde o termino da operação", "Aguarde");
      const res = await bannersService.create(formD, (event) => {
        const progress = Math.round((event.loaded * 100) / event.total);
        setState(progress - 1);
      });
      if (res) {
        await AuthService.whoami();
        setBannerCreated(true);
        setIsUploadingDoc(false);
      }
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess(
        "Banner criado com sucesso",
        "",
        true,
        "/banners",
        "Redirecionar para banners"
      );
    } catch (e) {
      toastError(e.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={submitForm}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
          resetForm,
        }) => {
          return (
            <Form id="user-create-form" autoComplete="off">
              <Card id="basic-info" sx={{ overflow: "visible" }}>
                {/* <Grid pt={3} px={3} spacing={1} container alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <MDTypography variant="h5">Novo Banner</MDTypography>
                  </Grid>
                </Grid> */}
                <MDBox p={3}>
                  <Grid container spacing={2}>
                    <BannerMenuAside
                      formData={{
                        values,
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        handleBlur,
                        resetForm,
                      }}
                      canvasData={{ canvas, setCanvas }}
                      removeBackground={{
                        isRemoveBackground,
                        setIsRemoveBackground,
                        removeBackgroundLoading,
                        setRemoveBackgroundLoading,
                      }}
                    />
                    <CanvasContainer
                      formData={{
                        values,
                      }}
                      canvasData={{ canvas, setCanvas }}
                      canvasCreated={{ bannerCreated }}
                      removeBackground={{
                        isRemoveBackground,
                        setIsRemoveBackground,
                        removeBackgroundLoading,
                        setRemoveBackgroundLoading,
                      }}
                    />
                  </Grid>
                </MDBox>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default BannersCreate;
