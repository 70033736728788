const dragNDropService = {
  drag: function (e) {
    try {
      e.dataTransfer.setData("id", e.target.id);
    } catch (error) {
      console.error("Erro ao obter item selecionado", error);
    }
  },

  drop: function (e) {
    try {
      e.preventDefault();
      const data = e.dataTransfer.getData("id");
      const el = document.getElementById(data);
      const type = el.tagName;

      return {
        element: el,
        type: type,
      };
    } catch (error) {
      console.error("Erro ao retornar elemento", error);
    }
  },
};

export default dragNDropService;
