import axios, { isAxiosError } from "axios";
import {
  AUTH_API,
  AUTH_WHOAMI_API,
  AUTH_RECOVERY_API,
  AUTH_VALIDATE_API,
} from "./auth";
import localStorageService from "../localStorage";
import StorageTypes from "../localStorage/storageTypes";
import customAxios from "../axiosCustom";

class AuthService {
  auth = async (encode, query = "") => {
    try {
      const response = await axios.post(
        `${AUTH_API}${query}`,
        {},
        {
          auth: {
            username: encode.document,
            password: encode.password,
          },
        }
      );

      if (!response) new Error("Não foi possível fazer login");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 400:
          case 404:
            throw new Error("Email e/ou senha inválidos");

          case 401:
            throw new Error(error.response.data.message);

          default:
            throw new Error("Não foi possível fazer login");
        }
      }

      throw new Error(error);
    }
  };

  whoami = async () => {
    try {
      const response = await customAxios.get(AUTH_WHOAMI_API);
      if (response) localStorageService.set(StorageTypes.USER, response.data);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 400:
          case 404:
            throw new Error(error.response.data.message);

          case 401:
            throw new Error(error.response.data.message);

          default:
            throw new Error("Não foi possível buscar usuário");
        }
      }

      throw new Error(error);
    }
  };

  sendEmail = async (email) => {
    try {
      const response = await axios.get(`${AUTH_RECOVERY_API}/${email}`);

      if (!response) new Error("Não foi possível enviar email");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(error.response.data);

          case 404:
            throw new Error("Informações inválidas");

          default:
            throw new Error("Não foi possível enviar email");
        }
      }

      throw new Error(error);
    }
  };

  changePassword = async (token, encode) => {
    try {
      const response = await axios.patch(
        `${AUTH_VALIDATE_API}/${token}`,
        {},
        {
          auth: {
            username: encode.password,
            password: encode.confirmPassword,
          },
        }
      );

      if (!response) new Error("Não foi possível mudar senha");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 400:
            throw new Error("Senhas não são iguais");

          case 403:
            throw new Error(
              "Token expirado, por favor, peça outro envio de e-mail"
            );

          case 404:
            throw new Error("Informações inválidas");

          default:
            throw new Error("Não foi possível mudar a senha");
        }
      }

      throw new Error(error);
    }
  };
}

export default new AuthService();
