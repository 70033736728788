export const getStyle = (size, textType, colorFont) => {
  let style = {};
  const [screenWidth, screenHeight] = size.split('x').map(Number);
  const isLandscape = screenWidth > screenHeight; // Verifica se é orientação paisagem
  const fontSizeFactor = screenWidth / 1920;

  // Configurações de posição baseadas na orientação
  const titleLeftPosition = isLandscape ? screenWidth * 0.05 : screenWidth * 0.07;
  const priceLeftPosition = isLandscape ? screenWidth * 0.05 : screenWidth * 0.3;
  const timesLeftPosition = isLandscape ? screenWidth * 0.05 : screenWidth * 0.312;
  const buyNowLeftPosition = isLandscape ? screenWidth * 0.05 : screenWidth * 0.3;

  const titleTopPosition = isLandscape ? screenHeight * 0.1 : screenHeight * 0.05;
  const priceTopPosition = isLandscape ? screenHeight * 0.42 : screenHeight * 0.6;
  const timesTopPosition = isLandscape ? screenHeight * 0.6 : screenHeight * 0.7;
  const buyNowTopPosition = isLandscape ? screenHeight * 0.8 : screenHeight * 0.85;

  if (textType === 'title') {
    style = {
      left: titleLeftPosition,
      top: titleTopPosition,
      fontSize: (isLandscape ? 100 : 80) * fontSizeFactor,
      textBackgroundColor: "transparent",
      stroke: "transparent",
      strokeWidth: 0,
      fontWeight: "",
      fill: colorFont,
      antiAlias: true,
      width: screenWidth * (isLandscape ? 0.4 : 0.9), // Ajusta largura para retrato
      textAlign: "left",
    };
  } else if (textType === 'price') {
    style = {
      left: priceLeftPosition,
      top: priceTopPosition,
      fontFamily: "Arial",
      fontSize: 180 * fontSizeFactor,
      textBackgroundColor: "transparent",
      stroke: "transparent",
      strokeWidth: 0,
      fontWeight: "bold",
      fill: colorFont,
      antiAlias: true,
      width: screenWidth * (isLandscape ? 0.4 : 0.9),
      textAlign: "left",
    };
  } else if (textType === 'buyNow') {
    style = {
      left: buyNowLeftPosition,
      top: buyNowTopPosition,
      fontFamily: "Arial",
      fontSize: 120 * fontSizeFactor,
      textBackgroundColor: "transparent",
      stroke: "transparent",
      strokeWidth: 0,
      fontWeight: "bold",
      fill: colorFont,
      antiAlias: true,
      width: screenWidth * (isLandscape ? 0.4 : 0.9),
      textAlign: "left",
    };
  } else if (textType === 'times') {
    style = {
      left: timesLeftPosition,
      top: timesTopPosition,
      fontFamily: "Arial",
      fontSize: 100 * fontSizeFactor,
      textBackgroundColor: "transparent",
      stroke: "transparent",
      strokeWidth: 0,
      fontWeight: "",
      fill: colorFont,
      antiAlias: true,
      width: screenWidth * (isLandscape ? 0.4 : 0.9),
      textAlign: "left",
    };
  }

  return style;
};

export function getImagePosition(size) {
  const newSize = getScreenSize(size);
  const [screenWidth, screenHeight] = newSize.split('x').map(Number);
  const isLandscape = screenWidth > screenHeight; // Verifica se é orientação paisagem

  const leftPosition = isLandscape ? screenWidth * 0.55 : screenWidth * 0.28; // Imagem centralizada em retrato
  const topPosition = isLandscape ? screenHeight * 0.13 : screenHeight * 0.15;

  return {
    left: leftPosition,
    top: topPosition,
  };
}

function getScreenSize({ x, y }) {
  const roundedX = Math.round(x);
  const roundedY = Math.round(y);
  return `${roundedX}x${roundedY}`;
}
