import { tooltipClasses, Tooltip, styled } from "@mui/material";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(0, 0, 0, 0.27)",
  },
}));

export default HtmlTooltip;
