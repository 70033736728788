import PropTypes from "prop-types";

import { ErrorMessage, Field } from "formik";

import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import MDInput from "shared/components/MDInput";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
function FormField({
  label,
  name,
  fieldVariant,
  fieldShrink,
  // isShowPassword,
  // handleTogglePasswordVisibility,
  ...rest
}) {
  const isPassword = rest.type === "password";

  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () =>
    setIsShowPassword(!isShowPassword);

  return (
    <MDBox mb={1.5}>
      <MDBox style={{ position: "relative" }}>
        {isPassword ? (
          <IconButton
            onClick={handleTogglePasswordVisibility}
            style={{
              position: "absolute",
              margin: "3px 0",
              right: "8%",
              zIndex: 5,
            }}
          >
            {isShowPassword ? (
              <Visibility color="info" />
            ) : (
              <VisibilityOff color="info" />
            )}
          </IconButton>
        ) : null}
        <Field
          {...rest}
          type={isPassword && isShowPassword ? "text" : rest.type}
          name={name}
          as={MDInput}
          label={label}
          fullWidth
          variant={fieldVariant}
          InputLabelProps={fieldShrink ? { shrink: fieldShrink } : null}
          autoComplete="off"
        />
      </MDBox>
      <MDBox>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          mt={0.75}
        >
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

FormField.defaultProps = {
  label: "",
  fieldVariant: "standard",
  fieldShrink: false,
  // isShowPassword: false,
  // handleTogglePasswordVisibility: () => {},
};

FormField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  fieldVariant: PropTypes.string,
  fieldShrink: PropTypes.bool,
  // isShowPassword: PropTypes.bool,
  // handleTogglePasswordVisibility: PropTypes.func,
};

export default FormField;
