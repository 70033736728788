import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { fabric } from "fabric";

export async function htmlToImage(elementId, width, height, name = "image") {
  const element = document.getElementById(elementId);

  html2canvas(element, {
    logging: true,
    letterRendering: 1,
    allowTaint: false,
    useCORS: true,
    scale: 1,
  }).then((canvas) => {
    const newCanvas = document.createElement("canvas");
    newCanvas.width = width;
    newCanvas.height = height;
    const context = newCanvas.getContext("2d");
    const scaleX = width / canvas.width;
    const scaleY = height / canvas.height;
    context.scale(scaleX, scaleY);
    context.drawImage(canvas, 0, 0);

    newCanvas.toBlob(function (blob) {
      if (blob) {
        saveAs(blob, `${name}.png`);
      } else {
        console.error("Erro ao criar o blob da imagem.");
      }
    });
  });
}

export async function htmlToImageZoomOut(originalCanvas, name = "image") {
  const canvasClone = new fabric.Canvas("canvas-clone");
  const zoomRes = 1 / originalCanvas.getZoom();
  canvasClone.setWidth(originalCanvas.getWidth());
  canvasClone.setHeight(originalCanvas.getHeight());
  canvasClone.setBackgroundColor(originalCanvas.backgroundColor);
  canvasClone.setZoom(1);
  canvasClone.setWidth(canvasClone.getWidth() * zoomRes);
  canvasClone.setHeight(canvasClone.getHeight() * zoomRes);
  canvasClone.setBackgroundImage(
    originalCanvas.backgroundImage,
    canvasClone.renderAll.bind(canvasClone)
  );

  originalCanvas.getObjects().forEach(function (obj) {
    var cloneObj = fabric.util.object.clone(obj);
    canvasClone.add(cloneObj);
  });
  canvasClone.renderAll();

  const canvasCloneElement = document.getElementById("canvas-clone");

  canvasCloneElement.toBlob(function (blob) {
    canvasClone.setZoom(originalCanvas.getZoom());
    canvasClone.forEachObject(function (object) {
      object.prevEvented = object.evented;
      object.prevSelectable = object.selectable;
      object.evented = false;
      object.selectable = false;
    });
    canvasClone.requestRenderAll();
    if (blob) {
      saveAs(blob, `${name}.png`);
    } else {
      console.error("Erro ao criar o blob da imagem.");
    }
  });

  // canvasClone.setZoom(originalCanvas.getZoom());
  // canvasClone.forEachObject(function (object) {
  //   object.prevEvented = object.evented;
  //   object.prevSelectable = object.selectable;
  //   object.evented = false;
  //   object.selectable = false;
  // });
  // canvasClone.renderAll();
  // canvasClone.dispose();
}

export async function htmlToFile(originalCanvas) {
  return new Promise((resolve, reject) => {
    const canvasClone = new fabric.Canvas("canvas-clone");
    const zoomRes = 1 / originalCanvas.getZoom();
    canvasClone.setWidth(originalCanvas.getWidth());
    canvasClone.setHeight(originalCanvas.getHeight());
    canvasClone.setBackgroundColor(originalCanvas.backgroundColor);
    canvasClone.setZoom(1);
    canvasClone.setWidth(canvasClone.getWidth() * zoomRes);
    canvasClone.setHeight(canvasClone.getHeight() * zoomRes);
    canvasClone.setBackgroundImage(
      originalCanvas.backgroundImage,
      canvasClone.renderAll.bind(canvasClone)
    );

    originalCanvas.getObjects().forEach(function (obj) {
      var cloneObj = fabric.util.object.clone(obj);
      canvasClone.add(cloneObj);
    });
    canvasClone.renderAll();

    const canvasCloneElement = document.getElementById("canvas-clone");

    canvasCloneElement.toBlob(function (blob) {
      canvasClone.setZoom(originalCanvas.getZoom());
      canvasClone.forEachObject(function (object) {
        object.prevEvented = object.evented;
        object.prevSelectable = object.selectable;
        object.evented = false;
        object.selectable = false;
      });
      canvasClone.requestRenderAll();
      if (blob) {
        resolve(blob);
      } else {
        reject("Erro ao criar o blob da imagem.");
      }
    });
  });
}
