/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
/* eslint-disable no-unused-vars */

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import Login from "shared/pages/login";
import ChangePassword from "shared/pages/change_password";
import Dashboard from "shared/pages/dashboard";

import CalibrationXML from "shared/pages/calibration";

import Banners from "./shared/pages/banners/index";
import BannersCreate from "./shared/pages/banners/create/index";
import BannersEdit from "./shared/pages/banners/edit/index";

import Admins from "shared/pages/admins";
import AdminsCreate from "shared/pages/admins/create";
import AdminsEdit from "shared/pages/admins/edit";

import Professionals from "shared/pages/professionals";
import ProfessionalsCreate from "shared/pages/professionals/create";
import ProfessionalsEdit from "shared/pages/professionals/edit";

import Templates from "shared/pages/templates";
import TemplatesCreate from "shared/pages/templates/create";
import TemplatesEdit from "shared/pages/templates/edit";

import CanvasSettingsPage from "shared/pages/canvas_settings";

const routes = [
  {
    name: "Login",
    key: "login",
    route: "/entrar",
    component: <Login />,
  },
  {
    name: "Trocar Senha",
    key: "change-password",
    route: "/validate",
    component: <ChangePassword />,
  },
  {
    name: "Trocar Senha token",
    key: "change-password-token",
    route: "/validate/:token",
    component: <ChangePassword />,
  },

  // sidenav
  // {
  //   roles: [0],
  //   type: "collapse",
  //   noCollapse: true,
  //   name: "Dashboard",
  //   key: "dashboards",
  //   route: "/dashboard",
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   component: <Dashboard />,
  // },

  // admins crud
  {
    roles: [0],
    type: "collapse",
    noCollapse: true,
    name: "Administradores",
    key: "admins",
    route: "/admins",
    icon: <Icon fontSize="medium">people</Icon>,
    component: <Admins />,
  },
  {
    roles: [0],
    name: "Criar Administrador",
    key: "admins-create",
    route: "/admins/criar",
    component: <AdminsCreate />,
  },
  {
    roles: [0],
    name: "Editar Administrador",
    key: "admins-edit",
    route: "/admins/editar/:uuid",
    component: <AdminsEdit />,
  },

  // users crud
  {
    roles: [0],
    type: "collapse",
    noCollapse: true,
    name: "Usuários",
    key: "users",
    route: "/users",
    icon: <Icon fontSize="medium">people</Icon>,
    component: <Professionals />,
  },
  {
    roles: [0],
    name: "Criar Usuário",
    key: "users-create",
    route: "/users/criar",
    component: <ProfessionalsCreate />,
  },
  {
    roles: [0],
    name: "Editar Usuário",
    key: "users-edit",
    route: "/users/editar/:uuid",
    component: <ProfessionalsEdit />,
  },

  // canvas settings
  {
    roles: [1],
    type: "collapse",
    noCollapse: true,
    name: "Configurações",
    key: "canvas_settings",
    route: "/settings",
    icon: <Icon fontSize="medium">settings</Icon>,
    component: <CanvasSettingsPage />,
  },

  // calibration
  // {
  //   roles: [1],
  //   type: "collapse",
  //   noCollapse: true,
  //   name: "Calibrar XML",
  //   key: "calibration",
  //   route: "/calibration",
  //   icon: <Icon fontSize="medium">file_open</Icon>,
  //   component: <CalibrationXML />,
  // },

  //templates
  {
    roles: [1],
    type: "collapse",
    noCollapse: true,
    name: "Modelos",
    key: "templates",
    route: "/templates",
    icon: <Icon fontSize="medium">collections</Icon>,
    component: <Templates />,
  },
  {
    roles: [1],
    name: "Criar Modelo",
    key: "templates-create",
    route: "/templates/criar",
    component: <TemplatesCreate />,
  },
  {
    roles: [1],
    name: "Editar Modelo",
    key: "templates-edit",
    route: "/templates/editar/:uuid",
    component: <TemplatesEdit />,
  },

  //banners
  {
    roles: [1],
    type: "collapse",
    noCollapse: true,
    name: "Banners",
    key: "banners",
    route: "/banners",
    icon: <Icon fontSize="medium">panorama</Icon>,
    component: <Banners />,
  },
  {
    roles: [1],
    name: "Criar Banner",
    key: "banners-create",
    route: "/banners/criar",
    component: <BannersCreate />,
  },
  {
    roles: [1],
    name: "Editar Banner",
    key: "banners-edit",
    route: "/banners/editar/:uuid",
    component: <BannersEdit />,
  },

  // Logout
  {
    type: "logout",
    noCollapse: true,
    icon: <Icon fontSize="medium">logout</Icon>,
    name: "Sair",
    key: "logout",
  },
];

export default routes;
