const initialValues = {
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  status: true,
  document: "",
  company_name: "",
  postal_code: "",
  street: "",
  neighborhood: "",
  city: "",
  state: "",
  complement: "",
  banner_credits: "",
};

export default initialValues;
