import { Divider } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import PropTypes from "prop-types";
import MDButton from "shared/components/MDButton";
import { useNavigate } from "react-router-dom";

function SuccessSnackBar({
  title,
  content,
  isRedirect,
  navigateRoute,
  navigateContent,
}) {
  const navigate = useNavigate();
  return (
    <>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        color="dark"
        p={1.5}
      >
        <MDBox display="flex" alignItems="center" lineHeight={0}>
          <MDTypography
            variant="button"
            fontWeight="medium"
            color="success"
            textGradient
          >
            {title}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center" lineHeight={0}>
          <MDTypography variant="caption" color="dark">
            Agora
          </MDTypography>
        </MDBox>
      </MDBox>
      <Divider sx={{ margin: 0 }} light={false} />
      <MDBox
        p={1.5}
        sx={{
          fontSize: ({ typography: { size } }) => size.sm,
          color: ({ palette: { text } }) => text.main,
        }}
      >
        {content}
      </MDBox>
      {isRedirect ? (
        <MDButton
          color="info"
          onClick={() => navigate(navigateRoute)}
          fullWidth
        >
          {navigateContent}
        </MDButton>
      ) : (
        <MDBox />
      )}
    </>
  );
}

SuccessSnackBar.defaultProps = {
  title: "Sucesso",
  content: "Operação executada com successo!",
  isRedirect: false,
  navigateRoute: "",
  navigateContent: "",
};

SuccessSnackBar.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  isRedirect: PropTypes.bool,
  navigateRoute: PropTypes.string,
  navigateContent: PropTypes.string,
};

export default SuccessSnackBar;
