const initialValues = {
  xmlLink: "",
  xmlFile: "",
};

export const initialValuesCalibrations = {
  product_key: "",
  pid: "",
  name: "",
  category: "",
  description: "",
  image_url: "",
  price: "",
  installment_price: "",
  installment_month: "",
};

export default initialValues;
