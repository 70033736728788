/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Button,
  Divider,
  Fade,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  styled,
  useTheme,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { fabric } from "fabric";
import MDButton from "shared/components/MDButton";
import MDTypography from "./../../../../components/MDTypography/index";
import {
  addArrowOnclick,
  addCircleOnclick,
  addImg,
  addSquareOnclick,
  addStarOnclick,
  addText,
  addTextOnclick,
  addTriangleOnclick,
  canvasPanning,
  canvasZoom,
  clipInCircle,
} from "../canvasFunctions";
import { handleValue } from "shared/components/autocomplete/autocompleteConfigs";
import { fontFamilyList } from "utils/essentialData";
import MDBox from "shared/components/MDBox";
import { isBackgroundColorDark } from "utils";

import SquareOutlinedIcon from "@mui/icons-material/SquareOutlined";
import StorageTypes from "shared/services/localStorage/storageTypes";
import localStorageService from "shared/services/localStorage";

function getSelectedObjects(canvas) {
  const selectedObjects = canvas.getActiveObjects();
  return selectedObjects;
}

// general items
export function PriorityUp({ dataItem }) {
  const { canvas, activeObj } = dataItem;
  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="priorityUp"
      onClick={() => {
        canvas.bringToFront(activeObj).renderAll();
      }}
    >
      <Icon title="Aumentar prioridade">keyboard_double_arrow_up</Icon>
    </IconButton>
  );
}

export function PriorityDown({ dataItem }) {
  const { canvas, activeObj } = dataItem;
  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="PriorityDown"
      onClick={() => {
        canvas.sendToBack(activeObj).renderAll();
      }}
    >
      <Icon title="Diminuir prioridade">keyboard_double_arrow_down</Icon>
    </IconButton>
  );
}

export function DeleteItem({ dataItem }) {
  const { canvas, activeObj } = dataItem;
  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="delete"
      onClick={() => {
        const multipleObjs = getSelectedObjects(canvas);
        multipleObjs.forEach(function (obj) {
          canvas.remove(obj);
        });
        canvas.discardActiveObject().renderAll();
      }}
    >
      <Icon title="Apagar objeto">delete</Icon>
    </IconButton>
  );
}

export function CloneCanvasObject({ dataItem }) {
  const { canvas, activeObj } = dataItem;
  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="delete"
      onClick={() => {
        canvas.getActiveObject().clone(function (cloned) {
          if (cloned) {
            canvas.discardActiveObject();
            cloned.set({
              top: cloned.top + 30,
              left: cloned.left + 30,
            });

            if (cloned.type === "activeSelection") {
              cloned.canvas = canvas;
              cloned.forEachObject(function (obj) {
                canvas.add(obj);
              });
              cloned.setCoords();
            } else {
              canvas.add(cloned);
            }
            canvas.setActiveObject(cloned);
            canvas.requestRenderAll();
          }
        });
      }}
    >
      <Icon title="Copiar objeto">content_copy</Icon>
    </IconButton>
  );
}

export function CanvasPanningOnOff({ dataItem }) {
  const { canvas, isPanningMode, setIsPanningMode } = dataItem;

  useEffect(() => {
    if (isPanningMode) canvasPanning(canvas, true);
    else if (isPanningMode === false) {
      canvasPanning(canvas, false);
    }
  }, [isPanningMode]);

  return (
    <IconButton
      size="medium"
      aria-label="delete"
      color="text"
      title="Ativar/Desativar panorâmica"
      onClick={() => {
        const valuePan = !isPanningMode;
        setIsPanningMode(valuePan);
      }}
    >
      <Icon fontSize="medium">{isPanningMode ? "block" : "open_with"}</Icon>
    </IconButton>
  );
}

export function CanvasUndo({ dataItem }) {
  const { canvas, resetMenus } = dataItem;

  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="AlignTextLeft"
      onClick={() => {
        canvas.undo();
        resetMenus();
      }}
    >
      <Icon title="Desfazer ação">undo</Icon>
    </IconButton>
  );
}

export function CanvasRedo({ dataItem }) {
  const { canvas, resetMenus } = dataItem;

  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="AlignTextLeft"
      onClick={() => {
        canvas.redo();
        resetMenus();
      }}
    >
      <Icon title="Refazer ação">redo</Icon>
    </IconButton>
  );
}

// text and textBox items
export function CanvasAddText({ dataItem }) {
  const { canvas, resetMenus } = dataItem;

  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="AlignTextLeft"
      onClick={() => {
        addText(canvas);
        resetMenus();
      }}
    >
      <Icon title="Adicionar texto">title</Icon>
    </IconButton>
  );
}

export function CanvasAddTextOnClick({ dataItem }) {
  const {
    canvas,
    resetMenus,
    isMouseDown,
    setIsMouseDown,
    menuTypeClickObj,
    setMenuTypeClickObj,
  } = dataItem;

  useEffect(() => {
    if (isMouseDown && menuTypeClickObj === "text")
      addTextOnclick(
        canvas,
        true,
        setIsMouseDown,
        resetMenus,
        setMenuTypeClickObj
      );
  }, [isMouseDown, menuTypeClickObj]);

  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="AlignTextLeft"
      onClick={() => {
        const value = !isMouseDown;
        setIsMouseDown(value);
        setMenuTypeClickObj("text");
        resetMenus();
      }}
    >
      <Icon title="Adicionar texto">title</Icon>
    </IconButton>
  );
}

export function AlignTextLeft({ dataItem }) {
  const { canvas, activeObj } = dataItem;
  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="AlignTextLeft"
      onClick={() => {
        activeObj.set("textAlign", "left");
        canvas.renderAll();
      }}
    >
      <Icon title="Alinhar texto á esquerda">format_align_left</Icon>
    </IconButton>
  );
}

export function AlignTextCenter({ dataItem }) {
  const { canvas, activeObj } = dataItem;
  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="AlignTextCenter"
      onClick={() => {
        activeObj.set("textAlign", "center");
        canvas.renderAll();
      }}
    >
      <Icon title="Centralizar">format_align_center</Icon>
    </IconButton>
  );
}

export function AlignTextRight({ dataItem }) {
  const { canvas, activeObj } = dataItem;
  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="AlignTextRight"
      onClick={() => {
        activeObj.set("textAlign", "right");
        canvas.renderAll();
      }}
    >
      <Icon title="Alinhar texto á direita">format_align_right</Icon>
    </IconButton>
  );
}

export function TextBold({ dataItem }) {
  const { canvas, activeObj } = dataItem;
  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="TextBold"
      onClick={() => {
        const objlenght = activeObj.text.length;
        const styles = activeObj.getSelectionStyles(0, objlenght, true);
        const isDefaultFontWeight = styles.every(
          (style) => style.fontWeight === "normal"
        );
        activeObj.setSelectionStyles(
          { fontWeight: isDefaultFontWeight ? "bold" : "normal" },
          0,
          objlenght
        );
        activeObj.set(
          "fontWeight",
          activeObj.fontWeight === "bold" ? "normal" : "bold"
        );
        canvas.renderAll();
      }}
    >
      <Icon title="Negrito">format_bold</Icon>
    </IconButton>
  );
}

export function TextItalic({ dataItem }) {
  const { canvas, activeObj } = dataItem;
  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="TextItalic"
      onClick={() => {
        const objlenght = activeObj.text.length;
        const styles = activeObj.getSelectionStyles(0, objlenght, true);
        const isDefaultFontStyle = styles.every(
          (style) => style.fontStyle === "normal"
        );
        activeObj.setSelectionStyles(
          { fontStyle: isDefaultFontStyle ? "italic" : "normal" },
          0,
          objlenght
        );
        activeObj.set(
          "fontStyle",
          activeObj.fontStyle === "italic" ? "normal" : "italic"
        );
        canvas.renderAll();
      }}
    >
      <Icon title="Itálico">format_italic</Icon>
    </IconButton>
  );
}

export function TextUnderLine({ dataItem }) {
  const { canvas, activeObj } = dataItem;
  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="TextUnderLine"
      onClick={() => {
        const objlenght = activeObj.text.length;
        const styles = activeObj.getSelectionStyles(0, objlenght, true);
        const isUnderline = styles.every((style) => style.underline === true);
        activeObj.setSelectionStyles(
          { underline: isUnderline ? false : true },
          0,
          objlenght
        );
        canvas.renderAll();
      }}
    >
      <Icon title="Sublinhado">text_format</Icon>
    </IconButton>
  );
}

export function TextColor({ dataItem }) {
  const { canvas, activeObj } = dataItem;

  const isDefaultColor = activeObj?.fill?.includes("rgb(0, 0, 0)");
  const [value, setValue] = useState(
    isDefaultColor ? "#000000" : activeObj.fill
  );

  useEffect(() => {
    setValue(isDefaultColor ? "#000000" : activeObj.fill);
  }, [activeObj]);

  return (
    <TextField
      size="small"
      type="color"
      title="Cor da fonte"
      value={value || "#000000"}
      sx={{ width: "80px", maxWidth: "80px", padding: 0 }}
      onChange={(e) => {
        const { value } = e.target;
        const objlenght = activeObj.text.length;
        activeObj.setSelectionStyles({ fill: value }, 0, objlenght);
        activeObj.set("fill", value);
        canvas.renderAll();
        setValue(value);
      }}
    />
  );
}

export function TextFontSize({ dataItem }) {
  const { canvas, activeObj } = dataItem;

  const initialfontSize = Number(
    Math.ceil(activeObj.fontSize * (activeObj.scaleY - 0.1)).toFixed(0)
  );
  const [value, setValue] = useState(initialfontSize);

  function handleCanvaEvent(canvaObject) {
    canvaObject.on("object:modified", function (o) {
      const { target } = o;
      const { type, width, height } = target;

      const newFontSize = activeObj.fontSize * activeObj.scaleY;
      setValue(Number(Math.ceil(newFontSize).toFixed(0)));
    });
  }

  function handleScaleByFontSize(res) {
    const newScaleY = Number(res / activeObj.fontSize);
    activeObj.set({
      scaleX: newScaleY,
      scaleY: newScaleY,
    });
    canvas.renderAll();
  }

  useEffect(() => {
    handleCanvaEvent(canvas);
    return () => {
      setValue();
    };
  }, [canvas]);

  return (
    <TextField
      size="small"
      type="number"
      title="Tamanho da fonte"
      value={value || 1}
      sx={{ width: "80px", maxWidth: "80px", padding: 0 }}
      onChange={(e) => {
        const res = e.target.value;
        handleScaleByFontSize(res);
        setValue(res);
      }}
      inputProps={{ inputMode: "numeric", pattern: "[1-9]*", min: "1" }}
    />
  );
}

export function TextFontFamily({ dataItem }) {
  const { canvas, activeObj } = dataItem;
  const [value, setValue] = useState(activeObj.fontFamily);
  const [initialFontFromAPI, setInitialFontFromAPI] = useState([]);

  useEffect(() => {
    setValue(activeObj.fontFamily);

    if (activeObj) {
      const array = localStorageService.get(StorageTypes.FONT_OPTIONS);
      setInitialFontFromAPI([...fontFamilyList, ...array]);
    }
  }, [activeObj]);

  return (
    <Autocomplete
      options={initialFontFromAPI ?? []}
      getOptionLabel={(option) => option || ""}
      value={handleValue(value)}
      isOptionEqualToValue={(o, v) => o === v}
      onChange={(e, v) => {
        canvas.requestRenderAll();
        if (v) {
          const objlenght = activeObj.text.length;
          const styles = activeObj.getSelectionStyles(0, objlenght, true);
          const isDefaultFontFamily = styles.every(
            (style) => style.fontFamily === "Arial"
          );
          activeObj.setSelectionStyles(
            { fontFamily: isDefaultFontFamily ? v : "Arial" },
            0,
            objlenght
          );
          activeObj.set("fontFamily", v);
          setValue(v);
          canvas.renderAll();
        }
        setValue(v);
      }}
      renderOption={(props, option) => (
        <li {...props} style={{ fontFamily: option }}>
          {option}
        </li>
      )}
      renderInput={(params, option) => (
        <TextField
          {...params}
          style={{ fontFamily: params.inputProps.value }}
          title="Fonte"
          size="small"
          variant="outlined"
          label="Fontes"
        />
      )}
      sx={{ width: "200px", py: 0 }}
    />
  );
}

// image items
export function ClipImageInCircle({ dataItem }) {
  const { canvas, activeObj } = dataItem;
  return (
    <IconButton
      color="text"
      size="medium"
      aria-label="ClipImageInCircle"
      onClick={() => clipInCircle(activeObj, canvas)}
    >
      <Icon title="Recortar imagem em círculo">fiber_manual_record</Icon>
    </IconButton>
  );
}

// draw and lines items
export function CanvasDrawOnOff({ dataItem }) {
  const { canvas } = dataItem;
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  return (
    <IconButton
      size="medium"
      aria-label="delete"
      color="text"
      onClick={() => {
        canvas.isDrawingMode = !canvas.isDrawingMode;
        setIsDrawingMode(canvas.isDrawingMode);
      }}
    >
      <Icon title="Ativar/Desativar desenho" fontSize="medium">
        {isDrawingMode ? "open_with" : "draw"}
      </Icon>
    </IconButton>
  );
}

export function CanvasDrawColor({ dataItem, label = "" }) {
  const { canvas, activeObj } = dataItem;
  const isDefaultColor = activeObj?.stroke?.includes("rgb(0, 0, 0)");
  const [value, setValue] = useState(
    isDefaultColor ? "#000000" : activeObj.stroke
  );
  useEffect(() => {
    setValue(activeObj.stroke);
  }, [activeObj]);
  return (
    <TextField
      size="small"
      type="color"
      title="Cor do contorno"
      label={label}
      value={value || "#000000"}
      sx={{ width: "80px", maxWidth: "80px", padding: 0 }}
      onChange={(e) => {
        const { value } = e.target;
        activeObj.set("stroke", value);
        canvas.renderAll();
        setValue(value);
      }}
    />
  );
}

export function CanvasDrawStrokeWidth({ dataItem, label = "" }) {
  const { canvas, activeObj } = dataItem;
  const [value, setValue] = useState(activeObj.strokeWidth);

  useEffect(() => {
    setValue(activeObj.strokeWidth);
  }, [activeObj]);
  return (
    <TextField
      size="small"
      type="number"
      title="Tamanho do contorno"
      label={label}
      value={value || 1}
      sx={{ width: "80px", maxWidth: "80px", padding: 0 }}
      onChange={(e) => {
        const { value } = e.target;
        activeObj.set("strokeWidth", Number(value));
        canvas.renderAll();
        setValue(value);
      }}
      inputProps={{ inputMode: "numeric", pattern: "[1-9]*", min: "1" }}
    />
  );
}

// ----------------------------- shapes items -----------------------------------------

export function CanvasMenuShapes({ dataItem }) {
  const { canvas, resetMenus } = dataItem;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    resetMenus();
  };

  const options = [
    {
      group: "Quadrados",
      items: [
        <SquareShapeClick dataItem={dataItem} label="Quadrado" />,
        <SquareShapeClick
          dataItem={dataItem}
          label="Quadrado arredondado"
          option="square_rounded_shape"
        />,

        // <SquareShape dataItem={{ canvas }} label="Quadrado" />,
        // <SquareShape
        //   dataItem={{ canvas }}
        //   label="Quadrado arredondado"
        //   option="square-rounded"
        // />,
      ],
    },
    {
      group: "Circulos",
      items: [
        <CircleShapeClick dataItem={dataItem} label="Círculo" />,
        // <CircleShape dataItem={{ canvas }} label="Círculo" />,
      ],
    },
    {
      group: "Triângulos",
      items: [
        <TriangleShapeClick dataItem={dataItem} label="Triângulo" />,
        // <TriangleShape dataItem={{ canvas }} label="Triângulo" />,
      ],
    },
    {
      group: "Setas",
      items: [
        <ArrowShapeClick dataItem={dataItem} label="Seta" />,
        // <ArrowShape dataItem={{ canvas }} label="Seta" />,
      ],
    },
    {
      group: "Estrelas",
      items: [
        <StarShapeClick dataItem={dataItem} label="Estrela" />,
        // <StarShape dataItem={{ canvas }} label="Estrela" />
      ],
    },
  ];

  return (
    <div>
      <MDButton
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<Icon>keyboard_arrow_down_icon</Icon>}
        sx={{ p: 0, minWidth: 0 }}
        size="large"
        title="Formas"
      >
        <Icon>interests</Icon>
      </MDButton>

      <Menu
        id="grouped-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <List>
          {options.map((option, index) => (
            <div key={option.group}>
              <MenuItem style={{ background: "#F5F6F8" }}>
                {option.group}
              </MenuItem>
              <Grid container>
                {option.items.map((item, index) => (
                  <Grid item key={index} onClick={handleClose}>
                    <ListItem button>{item}</ListItem>
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
        </List>
      </Menu>
    </div>
  );
}

export function ShapeColor({ dataItem, label = "" }) {
  const { canvas, activeObj } = dataItem;
  const isDefaultColor = activeObj?.fill?.includes(
    "rgb(0, 0, 0)" || "rgb(0, 0, 0, 0)"
  );

  const [value, setValue] = useState(
    isDefaultColor ? "#000000" : activeObj.fill
  );

  useEffect(() => {
    setValue(activeObj.fill);
  }, [activeObj]);

  return (
    <TextField
      size="small"
      type="color"
      title="Preenchimento da Forma"
      label={label}
      value={value || "#000000"}
      sx={{ width: "80px", maxWidth: "80px", padding: 0 }}
      onChange={(e) => {
        const { value } = e.target;
        activeObj.set("fill", value);
        canvas.renderAll();
        setValue(value);
      }}
    />
  );
}

export function SquareShape({
  dataItem,
  label = "",
  option = "square-default",
}) {
  const theme = useTheme();
  const { canvas } = dataItem;

  const shapeColor = isBackgroundColorDark(
    canvas.backgroundColor,
    "#ffffff",
    "#000000"
  );

  let square = "";
  const commonOptions = {
    left: 100,
    top: 100,
    width: 100,
    height: 100,
    fill: "rgb(0, 0, 0, 0)",
    stroke: shapeColor,
    strokeWidth: 1,
    strokeUniform: true,
  };

  return (
    <IconButton
      size="medium"
      title={
        option === "square-rounded"
          ? "Quadrado de pontas arredondadas"
          : "Quadrado"
      }
      onClick={() => {
        switch (option) {
          case "square-default":
            square = new fabric.Rect(commonOptions);
            break;
          case "square-rounded":
            square = new fabric.Rect({
              ...commonOptions,
              rx: 10,
              ry: 10,
            });
            break;
          default:
            square = new fabric.Rect(commonOptions);
        }
        if (option === "square-rounded") {
          square.on("scaling", function () {
            this.set({
              width: this.width * this.scaleX,
              height: this.height * this.scaleY,
              scaleX: 1,
              scaleY: 1,
            });
          });
        }

        canvas.add(square);
        canvas.setActiveObject(square);
      }}
    >
      <Icon>crop_square</Icon>
    </IconButton>
  );
}

export function CircleShape({ dataItem, label = "", options }) {
  const theme = useTheme();
  const { canvas } = dataItem;

  const shapeColor = isBackgroundColorDark(
    canvas.backgroundColor,
    "#ffffff",
    "#000000"
  );

  return (
    <IconButton
      size="medium"
      aria-label="circle"
      title="Círculo"
      onClick={() => {
        const circle = new fabric.Circle({
          left: 100,
          top: 100,
          radius: 50,
          fill: "rgb(0, 0, 0, 0)",
          stroke: shapeColor,
          strokeWidth: 1,
          strokeUniform: true,
        });
        canvas.add(circle);
        canvas.setActiveObject(circle);
      }}
    >
      <Icon>radio_button_unchecked_sharp</Icon>
    </IconButton>
  );
}

export function TriangleShape({ dataItem, label = "", options }) {
  const theme = useTheme();
  const { canvas } = dataItem;

  const shapeColor = isBackgroundColorDark(
    canvas.backgroundColor,
    "#ffffff",
    "#000000"
  );

  return (
    <IconButton
      size="medium"
      aria-label="circle"
      title="Triângulo"
      onClick={() => {
        const triangle = new fabric.Triangle({
          left: 100,
          top: 100,
          width: 100,
          height: 100,
          fill: "rgb(0, 0, 0, 0)",
          stroke: shapeColor,
          strokeWidth: 1,
          strokeUniform: true,
        });
        canvas.add(triangle);
        canvas.setActiveObject(triangle);
      }}
    >
      <Icon>change_history_two_tone</Icon>
    </IconButton>
  );
}

export function ArrowShape({ dataItem, label = "", options }) {
  const theme = useTheme();
  const { canvas } = dataItem;

  const shapeColor = isBackgroundColorDark(
    canvas.backgroundColor,
    "#ffffff",
    "#000000"
  );

  const arrowPoints = [
    { x: 0, y: 0 }, // Starting point
    { x: 40, y: 0 }, // Right end of the rod
    { x: 40, y: 20 }, // Top of arrow
    { x: 80, y: -20 }, // Center of arrowhead
    { x: 40, y: -60 }, // bottom of arrow
    { x: 40, y: -40 }, // Right end of the rod
    { x: 0, y: -40 }, // final point
  ];

  return (
    <IconButton
      size="medium"
      aria-label="arrow"
      title="Seta"
      onClick={() => {
        const arrow = new fabric.Polygon(arrowPoints, {
          left: 100,
          top: 100,
          fill: "",
          stroke: shapeColor,
          strokeWidth: 1,
          strokeUniform: true,
          flipY: true,
        });
        canvas.add(arrow);
        canvas.setActiveObject(arrow);
      }}
    >
      <Icon>east</Icon>
    </IconButton>
  );
}

export function StarShape({ dataItem, label = "", options }) {
  const theme = useTheme();
  const { canvas } = dataItem;

  const shapeColor = isBackgroundColorDark(
    canvas.backgroundColor,
    "#ffffff",
    "#000000"
  );

  const starPoints = [
    { x: 0, y: 0 }, // Starting point
    { x: 10, y: -30 },
    { x: 40, y: -30 },
    { x: 15, y: -50 },
    { x: 25, y: -80 },
    { x: 0, y: -60 }, // center of star
    { x: -25, y: -80 },
    { x: -15, y: -50 },
    { x: -40, y: -30 },
    { x: -10, y: -30 },
  ];

  return (
    <IconButton
      size="medium"
      aria-label="star"
      title="Estrela"
      onClick={() => {
        const star = new fabric.Polygon(starPoints, {
          left: 100,
          top: 100,
          fill: "rgb(0, 0, 0, 0)",
          stroke: shapeColor,
          strokeWidth: 1,
          strokeUniform: true,
          flipY: true,
        });
        canvas.add(star);
        canvas.setActiveObject(star);
      }}
    >
      <Icon>star_border</Icon>
    </IconButton>
  );
}

//shape by click
export function TriangleShapeClick({ dataItem, label = "", options }) {
  const theme = useTheme();
  const {
    canvas,
    resetMenus,
    isMouseDown,
    setIsMouseDown,
    menuTypeClickObj,
    setMenuTypeClickObj,
  } = dataItem;

  useEffect(() => {
    if (isMouseDown && menuTypeClickObj === "triangle_shape")
      addTriangleOnclick(
        canvas,
        true,
        setIsMouseDown,
        resetMenus,
        setMenuTypeClickObj
      );
  }, [isMouseDown, menuTypeClickObj]);

  return (
    <IconButton
      size="medium"
      aria-label="circle"
      title="Triângulo"
      onClick={() => {
        const value = !isMouseDown;
        setIsMouseDown(value);
        setMenuTypeClickObj("triangle_shape");
        resetMenus();
      }}
    >
      <Icon>change_history_two_tone</Icon>
    </IconButton>
  );
}

export function SquareShapeClick({
  dataItem,
  label = "",
  option = "square_shape",
}) {
  const theme = useTheme();
  const {
    canvas,
    resetMenus,
    isMouseDown,
    setIsMouseDown,
    menuTypeClickObj,
    setMenuTypeClickObj,
  } = dataItem;

  useEffect(() => {
    if (
      (isMouseDown && menuTypeClickObj === "square_shape") ||
      (isMouseDown && menuTypeClickObj === "square_rounded_shape")
    )
      addSquareOnclick(
        canvas,
        true,
        setIsMouseDown,
        resetMenus,
        setMenuTypeClickObj,
        menuTypeClickObj
      );
  }, [isMouseDown, menuTypeClickObj]);

  return (
    <IconButton
      size="medium"
      aria-label="circle"
      title={label}
      onClick={() => {
        const value = !isMouseDown;
        setIsMouseDown(value);

        setMenuTypeClickObj(option);
        resetMenus();
      }}
    >
      {option === "square_rounded_shape" ? (
        <Icon>crop_square</Icon>
      ) : (
        <SquareOutlinedIcon />
      )}
    </IconButton>
  );
}

export function CircleShapeClick({
  dataItem,
  label = "Círculo",
  option = "circle_shape",
}) {
  const theme = useTheme();
  const {
    canvas,
    resetMenus,
    isMouseDown,
    setIsMouseDown,
    menuTypeClickObj,
    setMenuTypeClickObj,
  } = dataItem;

  useEffect(() => {
    if (isMouseDown && menuTypeClickObj === "circle_shape")
      addCircleOnclick(
        canvas,
        true,
        setIsMouseDown,
        resetMenus,
        setMenuTypeClickObj,
        menuTypeClickObj
      );
  }, [isMouseDown, menuTypeClickObj]);

  return (
    <IconButton
      size="medium"
      aria-label="circle"
      title={label}
      onClick={() => {
        const value = !isMouseDown;
        setIsMouseDown(value);
        setMenuTypeClickObj(option);
        resetMenus();
      }}
    >
      <Icon>radio_button_unchecked_sharp</Icon>
    </IconButton>
  );
}

export function ArrowShapeClick({
  dataItem,
  label = "Círculo",
  option = "arrow_shape",
}) {
  const theme = useTheme();
  const {
    canvas,
    resetMenus,
    isMouseDown,
    setIsMouseDown,
    menuTypeClickObj,
    setMenuTypeClickObj,
  } = dataItem;

  useEffect(() => {
    if (isMouseDown && menuTypeClickObj === "arrow_shape")
      addArrowOnclick(
        canvas,
        true,
        setIsMouseDown,
        resetMenus,
        setMenuTypeClickObj,
        menuTypeClickObj
      );
  }, [isMouseDown, menuTypeClickObj]);

  return (
    <IconButton
      size="medium"
      aria-label="Seta"
      title={label}
      onClick={() => {
        const value = !isMouseDown;
        setIsMouseDown(value);
        setMenuTypeClickObj(option);
        resetMenus();
      }}
    >
      <Icon>east</Icon>
    </IconButton>
  );
}

export function StarShapeClick({
  dataItem,
  label = "Círculo",
  option = "star_shape",
}) {
  const theme = useTheme();
  const {
    canvas,
    resetMenus,
    isMouseDown,
    setIsMouseDown,
    menuTypeClickObj,
    setMenuTypeClickObj,
  } = dataItem;

  useEffect(() => {
    if (isMouseDown && menuTypeClickObj === "star_shape")
      addStarOnclick(
        canvas,
        true,
        setIsMouseDown,
        resetMenus,
        setMenuTypeClickObj,
        menuTypeClickObj
      );
  }, [isMouseDown, menuTypeClickObj]);

  return (
    <IconButton
      size="medium"
      aria-label="Seta"
      title={label}
      onClick={() => {
        const value = !isMouseDown;
        setIsMouseDown(value);
        setMenuTypeClickObj(option);
        resetMenus();
      }}
    >
      <Icon>star_border</Icon>
    </IconButton>
  );
}

/* ---------------------------------------------------------------------------------- */

// ----------------------------- zoom -------------------------------------------------

export function CanvasZoomIn({ dataItem }) {
  const { canvas, resetMenus, zoom, setZoom } = dataItem;
  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="AlignTextLeft"
      onClick={() => {
        canvasZoom(canvas, 1.2, { zoom, setZoom });
        resetMenus();
      }}
    >
      <Icon title="Aumentar zoom">zoom_in</Icon>
    </IconButton>
  );
}

export function CanvasZoomOut({ dataItem }) {
  const { canvas, resetMenus, zoom, setZoom } = dataItem;
  return (
    <IconButton
      size="medium"
      color="text"
      aria-label="AlignTextLeft"
      onClick={() => {
        canvasZoom(canvas, 0.8, { zoom, setZoom });
        resetMenus();
      }}
    >
      <Icon title="Diminuir zoom">zoom_out</Icon>
    </IconButton>
  );
}

// ----------------------------- file -------------------------------------------------

export function CanvasFile({ dataItem }) {
  const { canvas, resetMenus, zoom, setZoom } = dataItem;
  const fileInputRef = useRef(null);

  const handleFileSelected = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        addImg(e.target.result, canvas);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <MDBox>
      <input
        type="file"
        id="file-input"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelected}
        accept="image/png, image/jpeg, image/jpg, image/webp, image/gif"
      />
      <label htmlFor="file-input">
        <IconButton color="text" aria-label="Carregar Arquivo" component="span">
          <Icon title="Upload de imagem">cloud_upload</Icon>
        </IconButton>
      </label>
    </MDBox>
  );
}
