/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Card, Grid, Icon } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";
import DataTableApi from "shared/components/tables/DatatableApi";

import { useEffect, useState } from "react";
import MDButton from "shared/components/MDButton";
import NotificationItem from "shared/examples/Items/NotificationItem";
import ProfessionalsService from "shared/services/professionals/professionals.service";
import PopUp, { dialogButton } from "shared/components/pop_up";
import { useNavigate } from "react-router-dom";
import MDProgress from "shared/components/MDProgress";
import toastError from "shared/components/snackbar/error/toastError";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import { errorMultipleMsgToast } from "utils/errorStatusCode";
import { maskPhone } from "utils/masks";

function Professionals() {
  const navigate = useNavigate();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [userStatus, setUserStatus] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [filter, setFilter] = useState("");
  const [filterSearch, setFilterSearch] = useState("");

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const handlePhone = (value) => {
    if (!value || value === "") return " - ";
    return value.includes("+") ? value : `+55 ${maskPhone(value)}`;
  };

  const dataTableData = {
    columns: [
      {
        Header: "nome",
        accessor: "user",
        Cell: ({ value }) => `${value.first_name} ${value.last_name}`,
      },
      {
        Header: "email",
        accessor: "user.email",
      },
      {
        Header: "telefone",
        accessor: "user.phone",
        Cell: ({ value }) => handlePhone(value),
      },
      {
        Header: "Status",
        accessor: "user.status",
        Cell: ({ value }) => (value ? "Ativo" : "Inativo"),
      },
      {
        Header: "ações",
        accessor: "action",
        width: "15%",
      },
    ],

    rows: dataTable || [],
  };

  const handleCancelStatus = () => setOpenPopUp(false);

  const handleApplyStatus = async () => {
    try {
      let userStatus = rowSelected.original.user.status;
      userStatus = !userStatus;
      const data = {
        id: rowSelected.original.id,
        status: userStatus,
      };
      await ProfessionalsService.updateStatus(data);
      toastSuccess("Usuário editado com sucesso");
      loadUsers(tablePage, tableLimit, filterSearch);
    } catch (e) {
      errorMultipleMsgToast(e);
    } finally {
      setOpenPopUp(false);
    }
  };

  const loadUsers = async (page = 1, limit = 5, filter = "") => {
    try {
      setLoadingData(true);
      const filterValue = filter !== "" ? `&filter=${filter.trim()}` : "";
      const res = await ProfessionalsService.list(
        `?page=${page}&limit=${limit}${filterValue}`
      );

      if (res) {
        setDataTable(res.data);
        setTableTotal(res.meta.totalCount);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  // const handleChange = (event) => {
  //   const { value } = event.target;
  //   if (value === "") {
  //     loadUsers(1, tableLimit);
  //     setTablePage(1);
  //     setFilterSearch("");
  //   }
  //   setFilter(value);
  // };

  // const handleClickSearch = () => {
  //   setFilterSearch(filter.trim());
  //   setTablePage(1);
  //   loadUsers(1, tableLimit, filter);
  // };

  useEffect(
    () => loadUsers(tablePage, tableLimit, filterSearch),
    [tablePage, tableLimit]
  );

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pb={3}>
          <MDBox mb={3}>
            <Card>
              <MDBox p={3}>
                <MDBox lineHeight={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Usuários
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDBox width="12rem" ml="auto">
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          fullWidth
                          onClick={() => navigate("/users/criar")}
                        >
                          CRIAR
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                {/* <MDBox mt={2}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <MDInput
                      type="text"
                      name="filter"
                      value={filter}
                      size="small"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            sx={{ visibility: filter ? "visible" : "hidden" }}
                            size="small"
                            onClick={() =>
                              handleChange({ target: { value: "" } })
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={2} display="flex">
                    <MDButton
                      disabled={!filter}
                      variant="gradient"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={handleClickSearch}
                    >
                      BUSCAR
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox> */}
              </MDBox>
              {loadingData ? (
                <MDProgress
                  variant="gradient"
                  variantProgress="indeterminate"
                  color="primary"
                />
              ) : (
                <DataTableApi
                  table={dataTableData}
                  onRowSelected={setRowSelected}
                  entriesPerPage={{ defaultValue: tableLimit }}
                  setTableLimit={setTableLimit}
                  tableLimit={tableLimit}
                  setTablePage={setTablePage}
                  tablePage={tablePage}
                  tableTotal={tableTotal}
                >
                  {[
                    <NotificationItem
                      onClick={() => {
                        const { id } = rowSelected.original;
                        navigate("/users/editar/" + id);
                      }}
                      icon={<Icon>create</Icon>}
                      title="Editar"
                      key="option_edit"
                    />,
                    <NotificationItem
                      onClick={() => {
                        const { status } = rowSelected.original.user;
                        setOpenPopUp(true);
                        setUserStatus(status);
                      }}
                      icon={<Icon>create</Icon>}
                      title="Alterar status"
                      key="option_delete"
                    />,
                  ]}
                </DataTableApi>
              )}
            </Card>
          </MDBox>
        </MDBox>
      </DashboardLayout>
      <PopUp
        open={openPopUp}
        onClose={() => setRowSelected()}
        title="Alterar status"
        contentText={`Deseja ${userStatus ? "desativar" : "ativar"} o usuário?`}
        actions={[
          <MDButton
            color="dark"
            onClick={handleCancelStatus}
            style={dialogButton}
            key="cancel"
          >
            cancelar
          </MDButton>,
          <MDButton
            color="error"
            onClick={handleApplyStatus}
            style={dialogButton}
            key="confirm"
          >
            confirmar
          </MDButton>,
        ]}
      />
    </>
  );
}

export default Professionals;
