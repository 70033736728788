/* eslint-disable no-unused-vars */
import {
  CanvasAddText,
  CanvasAddTextOnClick,
  CanvasDrawOnOff,
  CanvasFile,
  CanvasMenuShapes,
  CanvasPanningOnOff,
  CanvasRedo,
  CanvasUndo,
  CanvasZoomIn,
  CanvasZoomOut,
} from "./index";

export function fixedMenuItemsArray(dataItem) {
  const {
    canvas,
    resetMenus,
    isPanningMode,
    setIsPanningMode,
    zoom,
    setZoom,
    isMouseDown,
    setIsMouseDown,
    menuTypeClickObj,
    setMenuTypeClickObj,
  } = dataItem;
  return [
    // {
    //   component: <CanvasDrawOnOff dataItem={dataItem} />,
    // },
    {
      component: (
        <CanvasMenuShapes
          dataItem={{
            canvas,
            resetMenus,
            isMouseDown,
            setIsMouseDown,
            menuTypeClickObj,
            setMenuTypeClickObj,
          }}
        />
      ),
    },
    {
      component: (
        <CanvasAddTextOnClick
          dataItem={{
            canvas,
            resetMenus,
            isMouseDown,
            setIsMouseDown,
            menuTypeClickObj,
            setMenuTypeClickObj,
          }}
        />
      ),
    },
    {
      component: (
        <CanvasPanningOnOff
          dataItem={{ canvas, isPanningMode, setIsPanningMode }}
        />
      ),
    },
    {
      component: <CanvasUndo dataItem={{ canvas, resetMenus }} />,
    },
    {
      component: <CanvasRedo dataItem={{ canvas, resetMenus }} />,
    },
    {
      component: (
        <CanvasZoomIn dataItem={{ canvas, resetMenus, zoom, setZoom }} />
      ),
    },
    {
      component: (
        <CanvasZoomOut dataItem={{ canvas, resetMenus, zoom, setZoom }} />
      ),
    },
    {
      component: <CanvasFile dataItem={{ canvas, resetMenus }} />,
    },
  ];
}
